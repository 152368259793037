import React, { lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import commonStyles from "../../../../css.module/common.module.css";
import { handleSoftware, handleSoftwareWithUser } from "../../../JobFlow";
import { useUser } from "../../../../../context/useContext";
import { useLocation } from "react-router";

const DefaultButton = lazy(() => import("../../../components/Buttons/Default"));

export default function StepOne() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const technicianUserId = queryParams.get("technicianId")
    ? queryParams.get("technicianId")
    : false;
  const jobIdFromUrl = queryParams.get("jobId")
    ? queryParams.get("jobId")
    : false;
  const repost = queryParams.get("repost") ? queryParams.get("repost") : false;
  const MobileJobSlice = useSelector((state) => state.JobSlice);
  const history = useHistory();
  const { user } = useUser();

  // useEffect(() => {
  //   // console.log("Debugging user", { user });
  // }, [user]);

  const handleButtonClick = (item) => {
    if (user) {
      handleSoftwareWithUser(
        item.id,
        history,
        technicianUserId,
        jobIdFromUrl,
        repost
      );
    } else {
      handleSoftware(item.id, history, technicianUserId);
    }
  };

  return (
    <>
      <div className={commonStyles.box}>
        <div className={commonStyles.title_group}>
          <span className={commonStyles.h2_title}>
            Let's get your problem solved!
          </span>
          <span className={commonStyles.paragraph_subtitle}>
            Tell us which issue you're experiencing
          </span>
        </div>
        <div className={commonStyles.boxes}>
          {MobileJobSlice && MobileJobSlice.CategoryList
            ? MobileJobSlice.CategoryList.map((item, index) => {
                return (
                  <DefaultButton
                    className={commonStyles.category_button}
                    key={index}
                    onClick={() => handleButtonClick(item)}
                    title={item.name}
                    subtitle={item.description}
                    showicons
                    group={item.id}
                  />
                );
              })
            : null}
          <div className={commonStyles.border_bottom + " invisible"}>
            <span className={commonStyles.link}>
              <span className={commonStyles.link_frame}>I'm not sure</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
