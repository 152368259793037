import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import crossIcon from "../../../../../../assets/images/modalCross.png";
import { MESSAGES, emailRegExp } from "../../../../../../constants";
import * as PromocodeApi from "../../../../../../api/promoCode.api";
import "./style.css";
import { openNotificationWithIcon } from "utils";

const PromoCodePopUp = ({
  open,
  setOpen,
  promoId,
  setPromoId,
  setPromoCodeDetails,
  setPromocodeAppliedSuccessfully,
  user,
  referralEmailAddress,
  setreferralEmailAddress,
  promoCodeResponseMethod,
  setPromoCodeResponseMethod,
  promoCodeAppliedSuccessfully
}) => {

  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    padding: "42px 22px",
  };

  const applyPromocode = async () => {
    setPromoCodeResponseMethod("");
    if (
      referralEmailAddress !== "" &&
      !emailRegExp.test(referralEmailAddress)
    ) {
      openNotificationWithIcon(
        "error",
        "Invalid Email",
        `Please enter a valid email.`
      );
      return;
    }

    if (promoId !== "") {
      // console.log("promocode entered", promoId);
      setPromoCodeDetails({});
      const promocode = promoId.trim();
      // retrieving promocode data to db
      const response = await PromocodeApi.retrievePromoData(promocode);
      console.log("response- when coupon code is applied", promocode);
      if (response) {
        if (response && response.message) {
          setPromoCodeResponseMethod(response.message);
          return;
        }
        if (
          response &&
          response.expiry_date &&
          new Date(response.expiry_date).setHours(0, 0, 0, 0) <
            new Date().setHours(0, 0, 0, 0)
        ) {
          setPromoCodeResponseMethod(MESSAGES[0]);
          return;
        }
        if (response && response.used_by) {
          if (user && user.id) {
            const findUser = response.used_by.find(
              (item) => item.user_id === user.id
            );
            // console.log("Check User Exists ?", findUser);
            if (findUser && !(JSON.stringify(findUser) === "{}")) {
              setPromoCodeResponseMethod(MESSAGES[1]);
              return;
            } else {
              setPromoCodeDetails(response);
              const message =
                response.discount_type === "fixed"
                  ? `Promocode of $${response.discount_value} is applied !!`
                  : `Promocode of ${response.discount_value}% is applied !!`;
              setPromocodeAppliedSuccessfully(true);
              openNotificationWithIcon("success", "Success", message);
              setPromoCodeResponseMethod("");
              setOpen(false);
            }
          }
        }
      } else {
        setPromoCodeResponseMethod(MESSAGES[4]);
        return;
      }
    } else {
      handleClose();
      return;
    }
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="promo-pop-up-style">
            <div className="close-icon">
              <CloseIcon
                onClick={handleClose}
                className="close-icon-btn"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="main-container-promo-modal">
              <div className="enter-code-div">
                <span className="enter-code-text font-nova">
                  Please enter your code
                </span>
              </div>
              <div className={"inputDivClassModal"}>
                <input
                  type={"text"}
                  placeholder={"Promo Code"}
                  className={"inputClassModal font-nova"}
                  value={promoId}
                  onChange={(e) => setPromoId(e.target.value)}
                  disabled={promoCodeAppliedSuccessfully}
                />
              </div>
              <div className={"inputDivClassModal"}>
                <input
                  type={"email"}
                  placeholder={"Referral email address"}
                  className={"inputClassModal font-nova"}
                  value={referralEmailAddress}
                  onChange={(e) => setreferralEmailAddress(e.target.value)}
                />
              </div>
              <Button
                variant="contained"
                className="contained-Verify-Card-btn"
                onClick={applyPromocode}
              >
                <span className="contained-Verify-Card-btn-text font-nova">
                  APPLY
                </span>
              </Button>
              {promoCodeResponseMethod !== "" && (
                <div className="not-valid-promo-code">
                  <img className="crossIcon-style" src={crossIcon} />
                  <span className="not-valid-promo-code-text font-nova">
                    {promoCodeResponseMethod}
                  </span>
                </div>
              )}
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default PromoCodePopUp;
