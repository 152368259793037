import React, { useCallback, useRef, useState } from "react";
import { Modal } from "antd";
import { IMPORT_CSV_HEADINGS } from "../../constants/index";
import { Button } from "react-bootstrap";
import uploadFileIcon from "../../assets/images/uploadFile.svg";
import { useDropzone } from "react-dropzone";
import ProgressBar from "react-bootstrap/ProgressBar";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import InviteUserFile from "../../assets/files/users_list.xlsx";
import * as XLSX from "xlsx/xlsx.mjs";
import { openNotificationWithIcon } from "utils";
import { inviteListOfUsers } from "../../api/invite.api";
import { Space, Spin } from "antd";
export const ImportCSVModal = ({
  openImportCSVModal,
  setOpenImportCSVModal,
  user,
  setRefreshInvites,
  setPriorOrExistedUser,
}) => {
  let randomString = (Math.random() + 1).toString(36).substring(7);
  const emailRegExp = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,3}$/;
  const ROLES = ["admin", "user"];
  const FILE_EXTENSION_SUPPORTED = ["xlsx", "csv"];

  const [progressBarStatus, setProgressBarStatus] = useState(0);
  const [uploadedFileDetails, setUploadedFileDetails] = useState({
    fileName: "",
    numberOfUsers: "",
    usersList: [],
  });
  const [disableButton, setDisableButton] = useState(true);
  const [loader, setLoader] = useState(false);

  const fileInputRef = useRef(null);

  /**
   * @description : this function will extract the uploaded file and then convert that data into JSON format and verify each and every object  have valid data
   * @author : Jagroop
   */
  const onDrop = useCallback((acceptedFiles) => {
    try {
      // Extract file and check fileName
      const file = acceptedFiles[0];
      const fileName = file.name;

      // here we check  if uploaded file is of correct type or not
      const splitFile = fileName.split(".");
      const fileExtension = splitFile[splitFile.length - 1];
      if (!FILE_EXTENSION_SUPPORTED.includes(fileExtension)) {
        openNotificationWithIcon("error", "Please upload valid CSV file !!");
        return;
      }

      // Extract File Details and Format to JSON and then throw error is data is nosiy
      if (file && fileName) {
        setProgressBarStatus(10);
        let reader = new FileReader();
        setProgressBarStatus(20);

        reader.onload = function (e) {
          setProgressBarStatus(30);

          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          if (data && workbook) {
            // console.log("workbook", workbook);
            // find the name of your sheet in the workbook first
            let worksheet = formatData("worksheet", workbook);
            setProgressBarStatus(60);
            // convert to json format
            const jsonData = formatData("json", worksheet);
            if (jsonData) {
              // console.log("jsonData", jsonData);
              const successfullyParsed = parseJSONData(jsonData);
              if (successfullyParsed) {
                setProgressBarStatus(80);
                setUploadedFileDetails({
                  fileName: fileName,
                  numberOfUsers: jsonData.length,
                  usersList: jsonData,
                });
                setDisableButton(false);
                setProgressBarStatus(100);
              } else {
                fileInputRef.current.value = null;
                removeFile();
              }
            }
          }
        };
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {
      console.log("error while  parsing xlsx file", error);
      removeFile();
    }
  }, []);

  /**
   * @description : This function is used to format the data on the basis of convertType
   * @author : Jagroop
   */
  const formatData = (convertType, data) => {
    try {
      if (convertType == "worksheet") {
        const worksheet = data.Sheets["Sheet1"];
        return worksheet;
      }
      if (convertType == "json") {
        const jsonData = XLSX.utils.sheet_to_json(data);
        return jsonData;
      }
      openNotificationWithIcon(
        "error",
        "Something went wrong.Please try again !!"
      );
      return false;
    } catch (error) {
      console.log("error in formatting data", error);
      return false;
    }
  };

  // This helper is used to remove  uploaded file and close the Modal
  const offImportCSVModal = () => {
    setOpenImportCSVModal(false);
    removeFile();
  };

  // This helper function is used to empty the stored details of file.
  const removeFile = () => {
    setProgressBarStatus(0);
    setUploadedFileDetails({ fileName: "", numberOfUsers: "", usersList: [] });
  };

  /**
   * @description : This function is used to send email request to the users extracted from CSV file.
   * @author : Jagroop
   */
  const importAndSendInvite = async () => {
    try {
      setLoader(true);
      setDisableButton(true);
      if (uploadedFileDetails && uploadedFileDetails?.usersList?.length > 0) {
        const businessName = user?.businessName ? user?.businessName : "";
        const inviteUsers = await inviteListOfUsers({
          usersList: uploadedFileDetails?.usersList,
          businessName,
        });
        if (inviteUsers && inviteUsers.success) {
          openNotificationWithIcon("success", "Successfully Invited !!");
          const priorOrKnownUsers = inviteUsers?.priorOrKnownUsers;
          if (priorOrKnownUsers.length > 0) {
            // console.log(
            //   "setPriorOrKnownUsers",
            //   JSON.stringify(priorOrKnownUsers)
            // );
            setPriorOrExistedUser({ list: priorOrKnownUsers, showAlert: true });
          }
        } else {
          openNotificationWithIcon(
            "error",
            "Something went wrong.Please try again !!"
          );
        }
      } else {
        openNotificationWithIcon("error", "Please upload CSV File !!");
      }
      offImportCSVModal();
      setRefreshInvites(randomString);
      setLoader(false);
      setDisableButton(false);
    } catch (error) {
      console.log("error  while inviting user", error);
      openNotificationWithIcon(
        "error",
        "Something went wrong.Please try again !!"
      );
      setLoader(false);
      setDisableButton(false);
    }
  };

  /**
   * @description : this  function is used to verify that there is no noisy data in the excel sheet
   * @author : Jagroop
   */
  const parseJSONData = (jsonData) => {
    try {
      for (let user of jsonData) {
        let email = user?.email;
        email = email.trim();
        let userType = user?.user_type;
        userType = userType.trim();

        if (email && userType && typeof userType == "string") {
          const formatteduserType = userType.toLowerCase();
          if (!email.match(emailRegExp)) {
            openNotificationWithIcon(
              "error",
              `${email} is not a valid email.Please update your CSV file !!`
            );
            return false;
          }
          if (!ROLES.includes(formatteduserType)) {
            openNotificationWithIcon(
              "error",
              `${userType} is not a valid user role for email ${email}.Please update your CSV file !!`
            );
            return false;
          }
        } else {
          openNotificationWithIcon(
            "error",
            "Please upload CSV file as per the format provided !!"
          );
          return false;
        }
      }
      return true;
    } catch (error) {
      console.log("error  while parseJSONData", error);
      openNotificationWithIcon(
        "error",
        "Please upload CSV file as per the format provided !!"
      );
      return false;
    }
  };

  return (
    <Modal
      visible={openImportCSVModal}
      onCancel={() => offImportCSVModal()}
      width={600}
      footer={null}
      maskClosable={false}
      destroyOnClose={true}
      closable={!loader}
      bodyStyle={{
        boxShadow: "-1px -1px 2px #FDFDFD, 2px 2px 6px #D2DBE2",
        background: "#FFFFFF",
        zIndex: 2,
      }}
    >
      {loader && (
        <div className="loader-overlay">
          <Spin style={{ color: "#17D6D7" }} size="large" />
        </div>
      )}
      <div className="d-flex justify-content-center flex-column align-items-center">
        <HeaderSection />
        <DragAndDropFileSection
          onDrop={onDrop}
          progressBarStatus={progressBarStatus}
          fileInputRef={fileInputRef}
        />
        {uploadedFileDetails &&
        uploadedFileDetails.fileName &&
        uploadedFileDetails.numberOfUsers ? (
          <FileDetailsSection
            uploadedFileDetails={uploadedFileDetails}
            removeFile={removeFile}
          />
        ) : null}

        <Button
          className="btn app-btn btn btn-primary import-user-btn-style mt-4"
          onClick={() => importAndSendInvite()}
          disabled={disableButton}
          style={disableButton ? { cursor: "not-allowed" } : {}}
        >
          {loader ? <Spin /> : "Import Users"}
        </Button>
      </div>
    </Modal>
  );
};

const HeaderSection = () => {
  const { mainHeading, subHeading, downloadFiltTitle } = IMPORT_CSV_HEADINGS;
  return (
    <>
      <p className="import-csv-main-head-style">{mainHeading}</p>
      <p className="import-csv-sub-head-style">{subHeading}</p>
      <a href={InviteUserFile} download>
        {" "}
        <p className="import-csv-downloadfile-txt-style">{downloadFiltTitle}</p>
      </a>
    </>
  );
};

const DragAndDropFileSection = ({
  onDrop,
  progressBarStatus,
  fileInputRef,
}) => {
  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <input
        type="file"
        id="attach-file"
        style={{ display: "none" }}
        onChange={(e) => onDrop(e.target.files)}
        ref={fileInputRef}
      />
      <div
        className="card file-upload-box-style d-flex justify-content-center flex-column align-items-center align-items-center"
        {...getRootProps()}
        onClick={handleIconClick}
      >
        <img
          src={uploadFileIcon}
          alt="Upload File Icon"
          className="upload-file-icon-style"
        />
        <p
          className="import-csv-main-head-style"
          style={{ fontSize: "1.03rem" }}
        >
          Drag & Drop or{" "}
          <span style={{ color: "#13BCBD" }}>
            {" "}
            <u>Upload CSV</u>
          </span>
        </p>
        {isDragActive || progressBarStatus ? (
          <ProgressBar
            animated
            now={progressBarStatus}
            className="progress-bar-style"
          />
        ) : null}
      </div>
    </>
  );
};

const FileDetailsSection = ({ uploadedFileDetails, removeFile }) => {
  return (
    <div className="row" style={{ margin: "10px" }}>
      <CheckIcon style={{ color: "#1BD4D5" }} />
      <p className="import-csv-sub-head-style">{`${uploadedFileDetails.fileName} (${uploadedFileDetails.numberOfUsers} Users)`}</p>
      <CancelIcon style={{ color: "#708390" }} onClick={removeFile} />
    </div>
  );
};
