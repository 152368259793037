import React from "react";
import logo from "../../../../assets/images/newLogoSmaller.png";

const Header = () => {
  return (
    <div>
      <img src={logo} className="business-plan-header-img" />
    </div>
  );
};

export default Header;
