import React, { useEffect, useState, useRef, useMemo } from "react";
import styled from "styled-components";
import "../../../../style.css";
import { useUser } from "../../../../context/useContext";
import Badge from "@mui/material/Badge";
import HeadingWithLogo from "pages/Customer/ProfileSetup/Components/HeadingWithLogo";
import goodGirl from "../../../../assets/images/Frame.png";
import { Modal } from "antd";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { useSocket } from "../../../../context/socketContext";
import Countdown, { zeroPad } from "react-countdown";
import {
  openNotificationWithIcon,
  get_or_set_cookie,
  getCookie,
  deleteParticipant,
  deleteParticipantForKeepSearching
} from "../../../../utils";
import mixpanel from "mixpanel-browser";
import * as WebSocket from "../../../../api/webSocket.api";
import * as JobApi from "../../../../api/job.api";
import notifySound from "../../../../assets/sounds/notification.mp3";
import ChatPanelTwilio from "components/ChatPanelTwilio";
import BasicButton from "components/common/Button/BasicButton";
import Box from "../../../../components/common/Box";
import { Opacity } from "@mui/icons-material";
import * as TwilioApi from "../../../../api/twilioChat.api";

let techWaitForCustomerStartMeeting = 59000;
let progress = 0;
let targetTime = 5;
const CountDown = ({ waitMeetingStartTimer, renderer }) =>
  useMemo(
    () => (
      <Countdown
        date={Date.now() + waitMeetingStartTimer}
        renderer={renderer}
        key={waitMeetingStartTimer}
      />
    ),
    [waitMeetingStartTimer]
  );

function WaitForMeetingStart({
  jobId,
  abc,
  setStep,
  webSocketId,
  handleStartCall,
  isDisabled,
  setIsDisabled,
}) {
  const { socket } = useSocket();
  const history = useHistory();
  const inboxRef = useRef();
  const { user } = useUser();
  const [notify, setNotifyTimes] = useState(0);
  const [percent, setPercent] = useState(0);
  const [jobEnded, setJobEnded] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [refetchChat, setRefetchChat] = useState(false);
  const [buttonKeyForChat, setButtonKeyForChat] = useState(false);
  const [socketHits, setSocketHits] = useState(0);
  const [showLoaderForChat, setShowLoaderForChat] = useState(null);
  const [chatPanelHasOpen, setChatPanelHasOpen] = useState(true);
  const [showChatPanel, setShowChatPanel] = useState(false);
  const [jobFromApi, setJobFromApi] = useState();
  const [safariBrowser, setSafariBrowser] = useState(false);
  const [isBrowserTypeSafari, setIsBrowserTypeSafari] = useState(false);
  const [waitMeetingStartTimer, setWaitMeetingStartTimer] = useState(
    techWaitForCustomerStartMeeting
  );
  const [timesUp, setTimesUp] = useState(false);
  const [showModalBeforeMeetingStart, setShowModalBeforeMeetingStart] =
    useState(false);
  let audio = new Audio(notifySound);
  const handleSendMessage = (message) => {
    socket.emit("talk-js-notification-to-customer", jobId);
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 13,
      backgroundColor: "red",
      padding: "0 4px",
    },
  }));

  const handelShowChat = () => {
    setChatPanelHasOpen(false);
    setShowChatPanel(true);
    setSocketHits(0);
    // fetchSingleJob()
  };

  useEffect(() => {
    let tokenFromCookie = getCookie("user_access");
    if (tokenFromCookie) {
      console.log(
        "Looking for tokenFromCookie from WaitForMeetingStart - found",
        // {token : "..."+tokenFromCookie.substring(tokenFromCookie.length - 10)}
      );
    } else {
      console.log(
        "Looking for tokenFromCookie from WaitForMeetingStart - not found"
      );
    }
    const fetchJobData = async () => {
      let response = await JobApi.retrieveJob(jobId);
      setJobFromApi(response);
      if (response.keepSearching) {
        const daletedTechData = await deleteParticipantForKeepSearching(response)
        await JobApi.updateJob(jobId, { keepSearching: false });
      }
    };
    fetchJobData();
  }, []);

  const counter = () => {
    if (progress === 100) {
      progress = 0;
    }
    progress = progress + 1;
    setPercent(progress);
  };

  useEffect(() => {
    socket.emit("join", jobId);
    setTimeout(() => {
      setIsloading(false);
    }, 8000);
    let timer = setInterval(counter, 1000);
    setTimeout(() => {
      setJobEnded(true);
      clearInterval(counter);
    }, 1800000);
    // 1800000
  }, []);

  const callApi = async (data_to_send) => {
    try {
      let webRes = await WebSocket.technician_polling(data_to_send);
      if (
        webRes.meetingStarted &&
        webRes.technician_id === user.technician.id &&
        webRes.job_id === jobId
      ) {
        clearInterval(counter);
        get_or_set_cookie(user);
        window.location.href =
          process.env.REACT_APP_MEETING_PAGE + `/meeting/technician/${jobId}`;
      }
    } catch (Err) {
      console.log("api error ");
    }
  };

  const handelCallBackPanel = (e) => {
    e.length === 2 ? setRefetchChat(true) : setRefetchChat(false);
    setSocketHits(0);
  };

  useEffect(() => {
    let userAgent = navigator.userAgent;
    let browserInfo =
      userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    let browserName = browserInfo[1];
    if (browserName === "Safari") {
      setIsBrowserTypeSafari(true);
    } else {
      setIsBrowserTypeSafari(false);
    }
  }, []);

  useEffect(() => {
     console.log("percent :::", percent);
    if (percent > 20 && percent % 25 === 0 && !jobEnded) {
      let data_to_send = {
        job_id: jobId,
        socket_id: webSocketId,
      };
      callApi(data_to_send);
    }

    if (percent === 100 && !jobEnded) {
      setTimeout(() => {
        progress = 0;
        setPercent(0);
        setNotifyTimes(notify + 1);
        openNotificationWithIcon(
          "info",
          "Sending Alert",
          `Notifying client to start the meeting for ${notify + 1} time`
        );
      }, 1000);
    }
  }, [percent]);

  useEffect(() => {
    socket.emit("join", jobId);
    socket.on("accept-job", (job) => {
      console.log(
        "accept-job socket received on technician side to change the page to meeting"
      );
      if (job.technician && user) {
        try {
          WebSocket.updateSocket(job["web_socket_id"], {
            hitFromTechnicianSide: true,
          });
        } catch (err) {
          console.log("accept-job error in Waitjob page>>>", err);
        }

        const res = JobApi.retrieveJob(jobId);
        res.then((data) => {
          if (data.technician.user.id === user.id) {
            clearInterval(counter);
            get_or_set_cookie(user);
            window.location.href =
              process.env.REACT_APP_MEETING_PAGE +
              `/meeting/technician/${jobId}`;
          }
        });
      }
    });
    if (user) {
      mixpanel.track("Technician - On Waiting For Client Confirmation Page ", {
        Email: user.email,
      });
    }
  }, [jobId, setStep, socket, history, user]);

  const handleSocketEvent = () => {
    // Increase the socket hits count
    setSocketHits((prevHits) => prevHits + 1);

    // Perform other actions
    audio.play();
    setButtonKeyForChat(true);
  };

  useEffect(() => {
    socket.on("open-chat-panel-talkjs", (data) => {
      if (data?.jobId === jobId) {
        handleSocketEvent();
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      const storedData = JSON.parse(
        localStorage.getItem("techWaitMeetingStartTimer")
      );
      if (storedData && storedData.jobId && jobId === storedData.jobId) {
        const now = new Date().getTime();
        const timeDiff = now - new Date(storedData.setWaitingTimer).getTime();
        setWaitMeetingStartTimer(techWaitForCustomerStartMeeting - timeDiff);
      }
    })();
  }, [jobId]);

  useEffect(() => {
    setIsDisabled(true);
  }, []);

  useEffect(() => {
    if (timesUp) {
      openNotificationWithIcon(
        "info",
        "Customer did not start the meeting. You can initiate it now"
      );
    }
  }, [timesUp]);

  useEffect(() => {
    if (showModalBeforeMeetingStart) {
      const timeInt = setInterval(() => {
        targetTime -= 1;
      }, 1000);

      setTimeout(() => {
        handleStartCall();
        if (timeInt) {
          clearInterval(timeInt);
        }
      }, 5000);
    }
  }, [showModalBeforeMeetingStart]);

  const handleStartMeeting = () => {
    setShowModalBeforeMeetingStart(true);
  };

  const renderer = useMemo(
    () =>
      ({ hours, minutes, seconds, completed }) => {
        // console.log("countdown completed>>>>>>>>>>>>>>>>>", completed);
        if (completed) {
          setIsDisabled(false);
          setTimesUp(true);
        }
        return (
          <span>
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        );
      },
    []
  );

  // const deleteParticipant = async (jobId) => {
  //   try {
  //     const getTwilioResponse = await TwilioApi.getTwilioChatDetails({
  //       chat_id: jobId,
  //     });
  //     // console.log("getTwilioResponse : ::::", getTwilioResponse);
  //     if (getTwilioResponse) {
  //       const chatId =
  //         getTwilioResponse.conversation[0]?.twilio_chat_service?.sid;
  //       if (chatId) {
  //         const findParticipants = await TwilioApi.twilioParticiantsList({
  //           conversationSid: chatId,
  //         });
  //         // console.log(
  //         //   "findParticipants : ::::",
  //         //   findParticipants.twilioData.participants
  //         // );
  //         const identityToMatch = user.id;
  //         for (const item of findParticipants.twilioData.participants) {
  //           if (item.identity === identityToMatch) {
  //             const deletedTech = await TwilioApi.deleteParticipantFromChat(
  //               chatId,
  //               item.sid
  //             );
  //             if (deletedTech) {
  //               return true;
  //             }
  //           }
  //         }
  //       }
  //     }
  //     // If no participant was found for deletion, you can return false
  //     return false;
  //   } catch (error) {
  //     console.error(
  //       "Error while deleting participant from the list of conversation",
  //       error
  //     );
  //     return false; // Return false in case of an error
  //   }
  // };

  useEffect(() => {
    const fetchJobData = async () => {
      let response = await JobApi.retrieveJob(jobId);
      if (user?.technician?.id !== response?.technician?.id) {
        const hasParitcipantDelete = await deleteParticipant(response.id,user.id);
        // console.log("hasParitcipantDelete :: :: :: :: ", hasParitcipantDelete);
        if (hasParitcipantDelete) {
          openNotificationWithIcon(
            "error",
            "job has already been accepted by another technician"
          );
          setTimeout(() => {
            window.location.href = `/dashboard/technician`;
          }, 2000);
        } else {
          openNotificationWithIcon(
            "error",
            "job has already been accepted by another technician"
          );
          setTimeout(() => {
            window.location.href = `/dashboard/technician`;
          }, 2000);
        }
      }
    };
    setTimeout(() => {
      fetchJobData();
    }, 2000);
  }, []);

  return (
    <>
      <Modal
        footer={null}
        closable={false}
        visible={isBrowserTypeSafari}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={false}
        width={616}
      >
        <div className="">
          <span style={{ fontSize: "18px" }}>
            To fully experience our share screen and remote access features,
            Geeker recommends switching to{" "}
            <span style={{ fontWeight: "bold" }}>Google Chrome</span> browser.
          </span>
        </div>

        <div className="d-flex justify-content-end">
          <BasicButton
            onClick={() => setIsBrowserTypeSafari(false)}
            btnTitle={"Close"}
            height={"40px"}
            width={"100px"}
            background={"#1bd4d5"}
            color={"#fff"}
          />
        </div>
      </Modal>

      <Modal
        footer={null}
        closable={false}
        visible={showModalBeforeMeetingStart}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={false}
        width={616}
      >
        <div className="">
          <span style={{ fontSize: "18px" }}>
            System will automatically call the customer on their mobile phone.
          </span>
        </div>

        <div className="d-flex justify-content-end">
          <BasicButton
            onClick={handleStartCall}
            disable={isDisabled}
            showSpinner={isDisabled}
            btnTitle={`OK (${targetTime})`}
            height={"40px"}
            width={"100px"}
            background={"#1bd4d5"}
            color={"#fff"}
          />
        </div>
      </Modal>

      <Container
        className="d-flex flex-column"
        style={
          showChatPanel ? { height: "unset", gap: "10px" } : { height: "100vh" }
        }
      >
        <HeadingWithLogo />
        <StepContainer>
          <NewJobContainer>
            <div
              clastName="wait-meeting-start-upper-div"
              style={{ display: "grid", gap: "10px" }}
            >
              <div className="wait-meeting-start-header-section">
                <div className="geek-meeting-start-header-inner mb-2">
                  <span>{`Please wait a minute for ${jobFromApi?.customer?.user?.lastName}  ${jobFromApi?.customer?.user?.firstName} to join`}</span>
                </div>
                <div className="geek-meeting-start-text-inner">
                  <span>
                    If they do not join a call within one minute, you will be
                    able to call them
                  </span>
                </div>
              </div>
              <div className="waiting-timer-start-section my-3">
                <CountDown
                  waitMeetingStartTimer={waitMeetingStartTimer}
                  renderer={renderer}
                />
              </div>
              <div className="meeting-start-button-section">
                <BasicButton
                  onClick={handleStartMeeting}
                  disable={isDisabled}
                  className={"basic-btn-meet  automation-start-job-tech"}
                  btnTitle={"Start Meeting & Call Customer"}
                  height={"72px"}
                  width={"391px"}
                  background={"#1bd4d5"}
                  style={{
                    background: "var(--Dark-Turquoise, #13BCBD)",
                    fontSize: "23px",
                  }}
                  color={"#fff"}
                />
                <Box className="invite-tech-btn chat-with-customer-link">
                  {chatPanelHasOpen ? (
                    <StyledBadge badgeContent={socketHits} color="secondary">
                      <span
                        className="chat-with-customer-text"
                        onClick={isDisabled ? null : handelShowChat}
                        style={
                          isDisabled
                            ? { opacity: "0.5", cursor: "not-allowed" }
                            : { opacity: "unset" }
                        }
                      >
                        Start chat with customer
                      </span>
                    </StyledBadge>
                  ) : (
                    <span className="chat-with-customer-text">
                      Start chat with customer
                    </span>
                  )}
                </Box>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div className=" d-flex justify-content-center align-items-center mt-4">
                  <img className="wait-job-good-girl" src={goodGirl} />
                </div>
              </div>
              {showChatPanel && jobFromApi && (
                <div className="d-flex justify-content-center mb-5">
                  <ChatPanelTwilio
                    job={jobFromApi}
                    width={"100%"}
                    height={"500px"}
                  />
                </div>
              )}
            </div>
          </NewJobContainer>
        </StepContainer>
        <div className="wait-job-meeting-footer"></div>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #e8f5ff 10.42%, #fff 100%);
  @media screen and (max-width: 991px) {
    .wait-job-meeting-footer {
      margin-top: -3px;
    }
  }
`;
const StepContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NewJobContainer = styled.div`

    width: 1060px;
    border-radius: 15px;
    border: 1.2px solid rgba(0, 0, 0, 0.20);
    background: #E8F3FA;
    display: flex;
    padding: 80px 100px 40px 100px;
    flex-direction: column;
    align-items: center;
    gap: 11px !important;
    flex-shrink: 0;
    position: relative;
    @media screen and (max-width: 1600px) {
        width:60%
        padding:25px 35px 25px 35px;
      
    }
    @media screen and (max-width: 1024px) {
        width:75%
        padding:25px 35px 25px 35px;
        .geek-meeting-start-header-inner{
            font-weight:600;
            font-size:21px;
            margin-bottom:12px;
        }
      
        .geek-meeting-start-text-inner{
            font-size:16px
        }
        .waiting-timer-start-section {
            font-size:25px
        }
        .meeting-start-button-section > .basicBtn{
            height:60px !important;
            width:219px !important;
            font-size:15px;     
        }
    }
    @media screen and (max-width: 763px) {
        width:75%
        padding:25px 35px 25px 35px;
        .geek-meeting-start-header-inner{
            font-weight:600;
            font-size:21px;
            margin-bottom:12px;
        }
      
        .geek-meeting-start-text-inner{
            font-size:16px
        }
        .waiting-timer-start-section {
            font-size:25px
        }
        .meeting-start-button-section > .basicBtn{
            height:60px !important;
            width:219px !important;
            font-size:15px;     
        }
    }
    @media screen and (max-width: 991px) {
        width:85%
    } 
`;

export default WaitForMeetingStart;
