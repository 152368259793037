import React, { Fragment } from "react";
import RecordingIcon from "../../../../assets/images/recording.svg"
import DownloadIcon from "../../../../assets/images/download.svg"
import "./jobrecordings.css"
import JobRecordingAction from "../JobRecordingAction/JobRecordingAction";

// This component will list recordings and disable them when not purchased ( or not VIP member), as well as display action buttons when clickability is disabled. ~ Jagroop
const JobRecordingPanel = React.memo(({ meetingRecordingDetail,job,user,setMeetingRecordingDetail }) => {
  const {meetingRecordings=[], isLocked=true} = meetingRecordingDetail
  return <>
   <div className={isLocked ? "disable-recording-style":""} >
   {meetingRecordings?.length > 0 && meetingRecordings.map((item, key) =>( <RecordingList item={item} key={key} isLocked={isLocked} />))}
   </div>
  {isLocked && <JobRecordingAction job={job} user={user} meetingRecordingDetail={meetingRecordingDetail} setMeetingRecordingDetail={setMeetingRecordingDetail} />}
  </>
});


// This will list down recordings with download option in Job details page ~ Jagroop
const RecordingList = React.memo(({item,isLocked}) => {


  const formatVideoLink=(videoUrl)=>{
       if(!isLocked){
        const updatedUrl = videoUrl.replace("http://","https://");
        return updatedUrl
       }
       return  false;
  }

  
  return<>
    <div className="p-2 d-flex flex-wrap align-items-center" key={item?.id} >
      <div className="col-md-6 d-flex flex-row justify-content-start">
        <img src={RecordingIcon} alt="recording-icon" height="25.18px" width="32px" />
        <p className="recording-title-style">{item?.recordingName}</p>
      </div>
      <a style={isLocked ? {pointerEvents :"none"} :{}} href={formatVideoLink(item.videoUrl)} target="_blank" download className="col-md-6  d-flex flex-row">
        <img src={DownloadIcon} alt="download-recording-icon" height="24px" width="24px" />
        <p className="rec-download-style">Download</p>
      </a>
    </div>
    <hr style={{ margin: "0px", padding: "0px" }} />
  </>;
});

export default JobRecordingPanel;
