import React, { useEffect, useState } from "react";
import CheckInCircle from "components/CheckInCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

import {
  SERVER_URL,
  VIDEO_RESUME_TITLE,
  VDO_SUCCESS_MSG,
  VIDEO_FILE_TYPES,
  ONE_FILE_VALIDATION,
  FILE_TYPE_NOT_SUPPORTED,
  FILE_SIZE_MSG,
  VDO_REMOVE_MSG,
  SOMETHING_WENT_WRONG_MSG,
  SECRET_KEY,
} from "../../../../../constants/index";
import { FaCloudUploadAlt } from "react-icons/fa";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Upload, message, Space, Button } from "antd";
import * as Techapi from "../../../../../api/technician.api";
import * as UploadApi from "../../../../../api/upload.api";
import {
  openNotificationWithIcon,
  setMixpanelEntry,
  videoExtensionUpdater,
  videoNameExtractor,
} from "utils";
import VideoRecordingModal from "./VideoRecordingModal";
import { useHistory } from "react-router";
import "./register-style.css"
const VideoResumeUpload = ({ user, refetch, setVideoUploadInProgress, videoUploadInProgress, setVideoUploaded }) => {
  const [videoStates, setVideoStates] = useState({
    videoFile: [],
    videoUrl: "",
    startRecording: false,
    fileName: "",
    privateVideoUrl: ""
  });
  const [isHovering, setIsHovering] = useState(false);
 
  const { Dragger } = Upload;
  const { id = "", profile = {} } = user?.technician;
  const history = useHistory();
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleTouchStart = () => {
    setIsHovering(true);
  };

  const handleTouchEnd = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    const isValidPath = history.location.pathname == "/technician/register_steps"
    if (isValidPath && videoUploadInProgress) {
      window.onbeforeunload = function () {
        return "Data will be lost if you leave the page, are you sure?";
      };
    }
    return () => {
      window.onbeforeunload = null;
    };

  }, [videoUploadInProgress, history])

  const props = {
    name: "file",
    accept: VIDEO_FILE_TYPES,
    multiple: false,
    videoFile: videoStates.videoFile,
    action: `${SERVER_URL}/api/uploads/videoResume`,
    data: { user: `${user?.id}` },
    headers: {
      "Authorization": `Bearer ${localStorage.getItem(SECRET_KEY)}`,
    },
    async onRemove() {
      await removeVideo();
    },
  };

  // This function is used to validate the uploaded file ~ Jagroop
  const beforeUploadValidation = (file) => {
    try {
      if (videoStates?.videoFile?.length > 1) {
        openNotificationWithIcon("error", "Warning", ONE_FILE_VALIDATION);
        return false;
      }
      let extension = (file?.type).split("/")[1]
      extension = videoExtensionUpdater(extension)
      console.log("extesion", extension)
      console.log("VIDEO_FILE_TYPES", { VIDEO_FILE_TYPES, type: file.type, extension })
      if (!VIDEO_FILE_TYPES.includes(`.${extension}`)) {
        openNotificationWithIcon("error", "Warning", FILE_TYPE_NOT_SUPPORTED);
        return false;
      }

      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 100) {
        openNotificationWithIcon("error", "Warning", FILE_SIZE_MSG);
        return false;
      }
      return true;
    } catch (error) {
      console.log("error while validating resume video upload", error);
      openNotificationWithIcon("error", "Warning", SOMETHING_WENT_WRONG_MSG);
      return false;
    }
  };

  // This function is responsible for uploading video resume to server and create a sharable link and update link in technician profile ~ Jagroop
  const uploadVideo = (info) => {
    try {

      const { status, response } = info.file;
      if (status !== "uploading") {
        console.log(
          "Video Upload : Non Uploading State",
          info.file,
          info.fileList
        );
      }
      if (status === "uploading") {
        setVideoUploadInProgress(true)
        setVideoStates((prev) => ({ ...prev, videoFile: [info.file] }));
      }
      if (status === "done" && response["publicUrl"] && response["privateURL"]) {
        // Set Success Message and upload the video URL to Techncian Profile
        message.success(`${info?.file?.name} file uploaded successfully.`);
        setVideoStates((prev) => ({ ...prev, videoFile: [info.file], fileName: info?.file?.name }));
        const videoFileURL = response.publicUrl;

        console.log("tech video file url:", videoFileURL);
        setVideoStates((prev) => ({ ...prev, videoFile: [info.file], videoUrl: videoFileURL, privateVideoUrl: response?.privateURL }));
        setVideoUploaded(true)
        setMixpanelEntry(user, "Technician - uploaded profile video");
        setVideoUploadInProgress(false)
      } else if (status === "error") {
        message.error(`${info?.file?.name} file upload failed.`);
        setVideoUploadInProgress(false)
        setVideoUploaded(false)
      }
      return true;
    } catch (error) {
      console.log("error while uploading resume video upload", error);
      setVideoUploadInProgress(false)
      setVideoUploaded(false)
      return false;
    }
  };

  // This function is used to remove video from server and technician profile as well ~ Jagroop
  const removeVideo = async () => {
    try {
      const videoUrl = videoStates.privateVideoUrl || profile.videoResume;
      if (videoUrl) {
        const videoId = videoNameExtractor(videoUrl);
        await UploadApi.removeVideoResume({ techId: id, videoId: videoId });
        setVideoStates({ videoFile: [], videoUrl: "", fileName: "" });
        setVideoUploadInProgress(false)
        setVideoUploaded(false)
        return true;
      } else {
        setVideoStates({ videoFile: [], videoUrl: "", fileName: "" });
        setVideoUploadInProgress(false)
        setVideoUploaded(false)
        return false;
      }

    } catch (error) {
      console.log("error while removing resume video upload", error);
      setVideoStates({ videoFile: [], videoUrl: "", fileName: "" });
      setVideoUploadInProgress(false)
      setVideoUploaded(false)
      return false;
    }
  };

  const deleteVideo = async () => {
    const delResponse = await removeVideo();
    if (delResponse) {
      refetch("callPageVdoResume")
      message.success(VDO_REMOVE_MSG);
    }
  }

  //  This function is used to check if video resume is already  uploaded or not ~ Jagroop
  const isResumeUploaded = () => {
    const haveVideoFile =
      videoStates?.videoFile?.length > 0 && videoStates?.videoFile[0]?.filename;
    const haveVideoURL = videoStates?.videoUrl;
    const isAlreadyHaveVdo = profile?.videoResume;

    if (haveVideoFile || haveVideoURL || isAlreadyHaveVdo) {
      return true;
    }
    return false;
  };

  return (
    <>

      <div className=" upload-div d-flex justify-content-center align-items-center flex-column">
        <div className="d-flex justify-content-start align-items-start w-100p check-in-circle-div">
          <CheckInCircle bgColor={isResumeUploaded() ? "cyan" : "grey"} />
          <span className="finalise-upload-text">{VIDEO_RESUME_TITLE}</span>
          <span
            className="video-resume-tooltip ml-1 position-relative"
            onMouseOver={handleMouseEnter}
            onMouseOut={handleMouseLeave}
            role="button"
            onTouchStart={handleTouchStart}
             onTouchEnd={handleTouchEnd}
            onFocus={() => setIsHovering(true)}
            onBlur={() => setIsHovering(false)}
          >
            <FontAwesomeIcon
              icon={faInfo}
              className="icon-of-i-video"
            />
            {isHovering ? (
              <div className="estimatePriceInfo-div">
                <div className="video-resume-tooltip-triangle"></div>
                <div className="video-resume-tooltip-inner ">
                  <p className="estimate-price">
                    File limit - 100MB<br />Accepted File Type - ".mp4 .mov .mkv .webm"
                  </p>
                </div>
              </div>
            ) : null}
          </span>

        </div>

        {videoStates.startRecording ? (
          <VideoRecordingModal startRecording={videoStates.startRecording} setVideoStates={setVideoStates} user={user} />
        ) : (
          <>
            <div className={`${profile?.videoResume ? "disable-image" : ""}`} style={{ maxWidth: "250px" }}>
              <Dragger {...props} onChange={uploadVideo} style={(isResumeUploaded() || videoUploadInProgress) ? { pointerEvents: "none", cursor: "default" } : ""} beforeUpload={beforeUploadValidation} >
                {isResumeUploaded() ? (
                  <div className="uploadResume d-flex justify-content-center align-items-center flex-column  ">
                    <span>{VDO_SUCCESS_MSG}</span>
                  </div>
                ) : (
                  <div className="uploadResume d-flex justify-content-center align-items-center flex-column  ">
                    <div className="cloud-upload">
                      <FaCloudUploadAlt className="cloud-icon" />
                    </div>
                    <span className=" drag-drop drag-drop-text">
                      Drag & Drop or &nbsp;
                      <span className="drag-drop-upload">Upload</span>
                    </span>
                  </div>
                )}
              </Dragger>
            </div>
          </>
        )}
        <div className="delete-resume-Button">
          {isResumeUploaded() ? (
            (videoStates?.fileName == "" || profile?.videoResume != "") && <ReusbaleBtn onClickEvent={deleteVideo} btnTitle="Delete Video" />
          ) : <></>
            // <>
            //   {videoStates?.startRecording ? (
            //      <ReusbaleBtn onClickEvent={toggleVideoRecording} btnTitle="Stop Recording" />
            //   ) : (
            //     <ReusbaleBtn onClickEvent={toggleVideoRecording} btnTitle="Start Recording" />
            //   )}
            // </>
          }
        </div>
      </div>
    </>
  );
};

const ReusbaleBtn = ({ onClickEvent, btnTitle }) => {
  return (
    <>
      <Space wrap>
        <Button type="text" onClick={onClickEvent} size="small">
          {btnTitle}
        </Button>
      </Space>
    </>
  );
};

export default React.memo(VideoResumeUpload);
