import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { useUser } from "../context/useContext";
import { useLocation } from "react-router";
import { useTools } from "../context/toolContext";
const PrivateRoute = ({ permission, component: C, props: cProps, ...rest }) => {
  // const location = useLocation();
  const { user, hasPermission } = useUser();

  const location = useLocation();
  const { setJobId } = useTools();
  const urlParams = new URLSearchParams(location.search);
  let storageObject = {};

  let to = "/dashboard";
  if(user && user.userType && user.userType === 'technician'){
    to = "/dashboard/technician";    
  }
  if (urlParams.get("checkStripeAccountStatus")) {
    localStorage.setItem("checkStripeAccountStatus", true);
  }
  storageObject.token = urlParams.get("t");
  let toIfNoPermission = "/login";
  if (
    urlParams.get("jobId") &&
    urlParams.get("newpost") &&
    urlParams.get("isMobilePost")
  ) {
    // console.log(">>>>> jobs >>>>>> auth ::: ", urlParams);
    toIfNoPermission =
      "/login?job-id=" +
      urlParams.get("jobId") +
      "&isMobilePost=" +
      urlParams.get("isMobilePost");
  }

  // if (urlParams.get("medium")) {
  //   toIfNoPermission =
  //     "/technician-details?technicianId=" +
  //     urlParams.get("technicianId") +
  //     "&medium=" +
  //     urlParams.get("medium");
  // }
  const currentPath = location.pathname;
  const pathSegments = currentPath.split('/');
  const techUniqueName = pathSegments.filter(Boolean).pop();
//   console.log("inside>>>>>>>>>>>    PrivateRoute",currentPath,techUniqueName)
  if (!user && currentPath && techUniqueName == 'verify-email') {
    storageObject.verifyEmail = true;
    localStorage.setItem('myStorage', JSON.stringify(storageObject));
    
  } else if (currentPath && pathSegments && techUniqueName !== "dashboard") {
   
    toIfNoPermission = "/tech-profile/" + techUniqueName;
  }

  // let loginPath = "/login";
  useEffect(() => {
    if (urlParams.get("checkJobId")) {
      // console.log("inside 0", urlParams.get("checkJobId"));
      setJobId(urlParams.get("checkJobId"));
      localStorage.setItem("checkjobdata", true);
    }
    console.log("LoginCallback User details in PrivateRoute (Line no. 63) ",user)
    console.log("LoginCallback going to redirect from privateroute file is to (Has permission) ",to)
    console.log("LoginCallback going to redirect from privateroute file is to (Has no permission) ",toIfNoPermission)
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          !permission || hasPermission(permission) ? (
            <C {...props} {...cProps} match={rest.computedMatch} />
          ) : (
            <Redirect to={to} />
          )
        ) : (
          <Redirect to={toIfNoPermission} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  props: PropTypes.object.isRequired,
};

export default PrivateRoute;
