import DashboardTabs from "components/Dashboard/DashboardTabs/DashboardTabs";
import React, { useEffect, useState, memo } from "react";
import { dashboardJobsData, paginationFinder } from "utils/dashboardUtilities";
import Pagination from "@mui/material/Pagination";
import { useTools } from "context/toolContext";
import { useJob } from "context/jobContext";
import * as JobApi from "../../../api/job.api";
import { useSocket } from "context/socketContext";
import { DashboardTab } from "components/Dashboard/Tabs";
const Dashboard = ({ user, allNotifications, latestJobIndex }) => {
  const { setJobId, setTypeForDetails, setStepDeciderDashboard } = useTools();
  const { fetchJob } = useJob();
  const { socket } = useSocket();
  const [dashboardTab, setDashboardTab] = useState({
    tab: "live-jobs-tab",
    tabData: [],
    totalJobs: 0,
    totalJobsPerTab: {},
  });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    totalPages: 1,
  });

  const [loader, setLoader] = useState(false);
  const JOBS_ARRAY = [ "Pending", "Scheduled","Waiting","Accepted"]
  useEffect(() => {
    (async function () {
      const jobDetails = window.sessionStorage.getItem("chatScreen");
      if (jobDetails) {
        const jobid = jobDetails;
        await fetchJob(jobid);
        await setJobId(jobid);
        let jobResponse = await JobApi.retrieveJob(jobid);
        setTypeForDetails("details");
        const scheduleAccepted = jobResponse.schedule_accepted == false;
        const techDeclinedJob =
          jobResponse?.tech_declined_ids.includes(user?.technician?.id) ==
          false;
        const isTech = user && user?.userType == "technician";
        if (scheduleAccepted && isTech && techDeclinedJob) {
          setTypeForDetails("apply");
        }
        setStepDeciderDashboard(6);
      }
    })();
  }, []);

  useEffect(() => {
    if (user && user?.userType) {
      setLoader(true);
      const initialRendrer = true;
      const selectedPage = pagination.pageNumber;
      jobsData(initialRendrer, selectedPage);
    }
  }, []);


  // This function is used to check if job coming from notification is valid to show on Dashboard  ~ Jagroop
  const validateJob = (job) => {
    const notNullJob = job?.status ? true : false;
    const validJobStatus = JOBS_ARRAY.includes(job?.status);
    const jobHaveNoTech = !job?.technician;
    const techAvailable = job?.technician;
    const userTechId = user?.technician?.id;
    const isTechJob = techAvailable == userTechId;
    const isTechAlreadyDeclined = job?.tech_declined_ids.includes(userTechId);
    const isCustomerDeclinedTech = job?.declinedByCustomer.includes(userTechId);

    const generalJob = notNullJob && validJobStatus;
    const isJobRelatedToTech = jobHaveNoTech || isTechJob;
    const notADeclinedJob = !isTechAlreadyDeclined && !isCustomerDeclinedTech;
    if (generalJob && isJobRelatedToTech && notADeclinedJob) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    async function fetchData() {
      const job = allNotifications?.[0]?.job ?? null;

      let newArray = dashboardTab.tabData;

      const ifExistingJob = newArray.find(
        (existingJob) => existingJob.id === job.id
      );

      if (!ifExistingJob) {
        if (validateJob(job)) {
          const haveCompletedJobs =
            dashboardTab?.totalJobsPerTab["completed-and-declined-tab"] &&
            dashboardTab?.totalJobsPerTab["completed-and-declined-tab"] != 0;

          if (dashboardTab.tab == "live-jobs-tab" && haveCompletedJobs) {
            newArray.unshift(job);

            let newJobdata = newArray.filter((obj, index, array) => {
              const firstIndex = array.findIndex((item) => item.id === obj.id);

              return index === firstIndex;
            });

            setDashboardTab({ ...dashboardTab, tabData: newJobdata });
          }

          if (
            dashboardTab.tab == "completed-and-declined-tab" &&
            job?.id &&
            dashboardTab?.tabData?.length == 0
          ) {
            let updatedJobsData = [];

            if (dashboardTab.tab == "live-jobs-tab") {
              updatedJobsData = dashboardTab.tabData;
            }

            updatedJobsData.unshift(job);

            setDashboardTab({
              tab: "live-jobs-tab",

              tabData: updatedJobsData,

              totalJobs: updatedJobsData.length,

              totalJobsPerTab: {
                "live-jobs-tab": updatedJobsData.length,

                "completed-and-declined-tab": 0,
              },
            });
          }
        }
      }

      const declinedJobs =
        allNotifications

          ?.map((notification) => notification?.job)

          ?.filter((job) => job?.status === "Declined") ?? [];

      const declinedJobIds = declinedJobs.map((job) => job.id);

      let updateArray = [...dashboardTab.tabData];

      const updatedArray = updateArray.filter(
        (job) => !declinedJobIds.includes(job.id)
      );

      setDashboardTab({ ...dashboardTab, tabData: updatedArray });
    }

    if (user?.userType == "technician" && allNotifications?.length > 0) {
      fetchData();
    }
  }, [allNotifications]);

  const jobsData = async (initialRendrer, selectedPage) => {
    try {
      const resultantObj = await dashboardJobsData(
        user,
        initialRendrer,
        user?.userType,
        dashboardTab.tab,
        selectedPage
      );
      // console.log("resultantObj", resultantObj);
      const totalJobs = resultantObj?.totalJobs ?? 0;
      const totalPages = paginationFinder(totalJobs);
      setDashboardTab(resultantObj);
      setPagination((prevState) => ({
        ...prevState,
        totalPages: totalPages,
        pageNumber: selectedPage,
      }));
      console.log("totalPagestotalPages", {
        totalJobs,
        totalPages,
        pagination,
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    socket.on("scheduled-call-alert", function (receiver) {
      try {
        // console.log("Inside socket FetchJob ::::1");
        jobsData(true, 1);
      } catch (err) {
        console.log("this is the error", err);
      }
    });
    socket.on("update-dashboard-status", () => {
      if (user.userType === "technician") {
        jobsData(true, 1);
      }
    });
    socket.on("join-scheduled-call", function (receiver) {
      try {
        // console.log("Inside socket FetchJob ::::2");
        jobsData(true, 1);
      } catch (err) {
        console.error("join-scheduled-call error :", err);
      }
    });

    socket.on("set-join-on-dashboard", function (receiver) {
      if (receiver.tech) {
        // console.log("inside receiver.tech");
        jobsData(true, 1);
      }
    });
    socket.on("technician:assigned", (data) => {
      // console.log("data >>>>>>", data);
      try {
        if (
          user &&
          user?.technician &&
          data?.technician == user?.technician?.id
        ) {
          jobsData(true, 1);
        }
      } catch (err) {
        console.error("error in technician:assigned socket >>>", err);
      }
    });
    socket.on("decline-technician", (receiver) => {
      // console.log("socketthere");
      if (
        user &&
        user.technician &&
        receiver.res != undefined &&
        user.technician.id === receiver.res.id
      ) {
        jobsData(true, 1);
      }
    });
    socket.on("call:started-customer", () => {
      if (user) {
        jobsData(true, 1);
      }
    });

    socket.on("update-job-technician", async (data) => {
      if (user) {
        if (user?.id === data?.techUserId) {
          jobsData(true, 1);
        }
      }
    });
    socket.on("update-job-customer", async (data) => {
      if (user) {
        if (user?.id === data?.cusUserId) {
          jobsData(true, 1);
        }
      }
    });
  }, [socket]);

  const setSelectedPageData = async (event, value) => {
    setLoader(true)
    let selectedPage = Number(value);
    if (selectedPage && selectedPage != pagination.pageNumber) {
      const initialRendrer = false;
      await jobsData(initialRendrer, selectedPage);
    }
    setLoader(false)
  };

  return (
    <>

      <DashboardTabs
        setDashboardTab={setDashboardTab}
        dashboardTab={dashboardTab}
        user={user}
        setPagination={setPagination}
        setLoader={setLoader}
        loader={loader}
      />
      <Pagination
        className="mui-pagination"
        page={pagination.pageNumber}
        count={pagination.totalPages}
        variant="outlined"
        shape="rounded"
        onChange={setSelectedPageData}
      />
    </>
  );
};

export default Dashboard;
