import { Modal } from "antd";
import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import hellBoy from "../../../../assets/images/man2.svg";
import { useAuth } from "../../../../context/authContext";

function AdminReview() {
  const { logout } = useAuth();

  /**
   * Function that handles the logout button to logout the user
   * @author : Kartik
   **/
  const Logout = useCallback(() => {
    Modal.confirm({
      title: "Logout Now?",
      okText: "Logout",
      cancelText: "Cancel",
      className: "logout-modal",
      onOk() {
        logout();
      },
    });
  }, [logout]);

  return (
    <div className="d-flex justify-content-center align-items-center flex-column log-out-outer">
      <div className="inner-div-admin-review">
        <Stack
          spacing={2}
          direction="row"
          className="d-flex justify-content-center align-items-center flex-column gap-20"
        >
          <Button
            className="logout-btn"
            style={{ textTransform: "none" }}
            onClick={Logout}
            variant="text"
          >
            Logout
          </Button>
          <img
            src={hellBoy}
            height={"200px"}
            className="d-flex justify-content-center align-items-center"
          />
        </Stack>
        <div
          className="d-flex justify-content-center align-items-center finalise-upload-div-admin  admin-review-div flex-column"
          style={{ marginTop: "unset" }}
        >
          <h1 className="thank-u-admin">Thank You</h1>
          <span className="admin-review-css">
            Our Hiring Manager will review your results and reach out shortly
            with the next steps. Please look out for an email from Geeker within
            the next 2-3 business days. Have a great day!
          </span>
        </div>
      </div>
    </div>
  );
}

export default AdminReview;
