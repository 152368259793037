import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ManOne from "../../../assets/images/Characters.png";
import ManTwo from "../../../assets/images/man2.svg";
import Woman from "../../../assets/images/woman1.svg";
import {
  fetchSoftwareList,
  fetchSoftwareCategoryList,
} from "../../../stores/devices/mobile/Job/AsyncActions";
import Header from "../../Components/header";
import {
  InfoTagWithIcon,
  RoundBox,
} from "../../Components/styled.components/job_detail.styled";
import {
  Content,
  Footer,
  LayoutContainer,
} from "../../Components/styled.components/layout.styled";
import commonStyles from "../../css.module/common.module.css";
import { CheckIcon } from "../components/Icons";
import { useUser } from "../../../context/useContext";
import * as JobApi from "../../../api/job.api";

export default function Layout(props) {
  const dispatch = useDispatch();
  const MobileJobState = useSelector((state) => state.JobSlice);
  let location = useLocation();
  const [pageCheck, setPageCheck] = useState(false);
  const [Character, setCharacter] = useState(ManOne);
  const [showHeaderAndFooter, setShowHeaderAndFooter] = useState(false);
  const { user } = useUser();
  const [isFirsJob, setIsFirsJob] = useState(false);

  useEffect(() => {
    dispatch(fetchSoftwareList());
    dispatch(fetchSoftwareCategoryList());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (user && user?.customer && user?.customer?.id) {
        // getting total jobs of customer from DB
        const totalNumberOfJobsOfCustomer = await JobApi.getTotalJobs({
          customer: user.customer.id,
        });
        if (totalNumberOfJobsOfCustomer === 0) setIsFirsJob(true);
      }
    })();
  }, [user]);

  const checkIfIncludePages = () => {
    return (
      location.pathname === "/select-problem" ||
      location.pathname === "/select-description" ||
      location.pathname === "/select-area" ||
      location.pathname === "/issue-description"
    );
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/create-job":
        if (!user) {
          setCharacter(ManOne);
          setShowHeaderAndFooter(true);
        }
        break;
      case "/post-job":
        setCharacter(ManOne);
        setShowHeaderAndFooter(true);
        break;
      case "/select-software":
        if (!user) {
          setShowHeaderAndFooter(true);
          setCharacter(ManTwo);
        }
        break;
      case "/select-category":
        setShowHeaderAndFooter(true);
        setCharacter(ManTwo);
        break;
      case "/select-problem":
        if (!user) {
          setShowHeaderAndFooter(true);
          setCharacter(Woman);
        }
        break;
      case "/select-area":
        setShowHeaderAndFooter(true);
        setCharacter(Woman);
        break;
      case "/issue-description":
        setShowHeaderAndFooter(true);
        setCharacter(Woman);
        break;
      case "/select-description":
        setShowHeaderAndFooter(true);
        setCharacter(Woman);
        break;
      default:
        setCharacter(Woman);
    }

    setPageCheck(checkIfIncludePages());
  }, [location.pathname, dispatch]);

  return (
    <LayoutContainer>
      {showHeaderAndFooter && (
        <Header
          showBackButton={location.pathname !== "/create-job"}
          setShowHeaderAndFooter={setShowHeaderAndFooter}
        />
      )}
      <Content {...props}>{props.content}</Content>
      {MobileJobState && MobileJobState.Selected && pageCheck ? (
        <RoundBox>
          <InfoTagWithIcon>
            <CheckIcon />
            <span>Get help in 10 minutes</span>
          </InfoTagWithIcon>
          <div className={commonStyles.your_job_box}>
            <div className={commonStyles.your_job_box__title}>
              <span>Your Job</span>
              <div className="your_job_box__title__div"></div>
            </div>
            <div className={commonStyles.your_job_box__job}>
              <div className={commonStyles.your_job_box__job_icon_with_text}>
                <div className={commonStyles.your_job_box__job_image}>
                  <img
                    className={commonStyles.your_job_box__job_img}
                    src={MobileJobState && MobileJobState.Selected.blob_image}
                    alt="icon"
                  />
                </div>
                <span className={commonStyles.your_job_box__job_text}>
                  {MobileJobState && MobileJobState.Selected.name}
                </span>
              </div>
            </div>
            <div className={commonStyles.your_job_box__job_line}></div>
            <div className={commonStyles.your_job_box__job_detail_box}>
              <div className={commonStyles.your_job_box__job_frame_1}>
                <div className={commonStyles.div}>
                  <span className={commonStyles.price_per_minute}>
                    Price per min
                  </span>
                  <div className={commonStyles.your_job_box__job_frame_1_price}>
                    <span
                      className={
                        commonStyles.your_job_box__job_frame_1_price_text
                      }
                    >
                      ${MobileJobState && MobileJobState.Selected.ratePerMinute}
                    </span>
                  </div>
                </div>
                {/* <span className={commonStyles.six_minute_text}>(6 min increments)</span> */}
              </div>
              {isFirsJob && <div className={commonStyles.your_job_box__job_frame_2}>
                <span className={commonStyles.your_job_box__job_frame_2__info}>
                  <span className={commonStyles.six_minute_text_frame_two}>
                    <span
                      className={commonStyles.six_minute_text_frame_two_text}
                    >
                      First time User 6 minutes free
                    </span>
                  </span>
                </span>
              </div>}
            </div>
          </div>
        </RoundBox>
      ) : null}
      {showHeaderAndFooter && (
        <Footer>
          <img
            src={Character}
            alt="Character"
            className={
              pageCheck
                ? commonStyles.mobile_character
                : commonStyles.mobile_character_two
            }
          />
          <span className={commonStyles.footer_mobile_text}>Geeker.co</span>
        </Footer>
      )}
    </LayoutContainer>
  );
}
