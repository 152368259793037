import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import {
  btnListToShowForCustomer,
  btnListToShowForTech,
} from "utils/dashboardUtilities";
import CustomerJobsActiveBtn from "./CustomerJobsActiveBtn";
import TechnicianJobsActiveBtn from "./TechnicianJobsActiveBtn";
import * as JobApi from "../../../api/job.api"
import { useSocket } from "context/socketContext";
import mixpanel from "mixpanel-browser";
import * as WebSocket from  "../../../api/webSocket.api"
import { GAevent, get_or_set_cookie, openNotificationWithIcon, PushUserDataToGtm } from "utils";
const ShowButtonDecider = React.memo(({ jobData, user, userType,setLoader }) => {
  const [btnsToShowList, setBtnsToShowList] = useState({});
  const { socket } = useSocket();
  useEffect(() => {
    if (jobData && user) {
      if (userType == "customer") {
        const response = btnListToShowForCustomer(jobData, user);
        // console.log(
        //   "btnListToShowForCustomerbtnListToShowForCustomer",
        //   response
        // );
        setBtnsToShowList(response);
      } else {
        const response = btnListToShowForTech(jobData, user);
        // console.log(
        //   "btnListToShowForTechbtnListToShowForTechbtnListToShowForTech",
        //   response
        // );
        setBtnsToShowList(response);
      }
    }
  }, [jobData]);




  	/**
	* This function will is common function for decline the job by tech
	* @response : jobid(Type: String): Job id which is declined by tech
	*		techAlert(Type:Boolean): True for other case and in schedule job decline it will only decline the without notification
	* @author : unknown
	* @note: this function updated by Ridhima Dhir by adding techAlert flag
	*/

	const decline_job_by_technician = async (jobid, alert = true, reason = null) => {
		// find job details
		setLoader(true)
		let selectedJob = await JobApi.retrieveJob(jobid)
		let tech_id = user.technician.id
		let notifiedTechs = selectedJob.notifiedTechs;
		// console.log("notifiedTechs ::: before", notifiedTechs)
		// get notifiedTech object and reverse the object bcz notifiedTech have multiple same value
		// bcz after decline find tech function will work and push tech values agagin.
		// in secondryTime true: notification again goes to all tech but exclude declined techs.
		notifiedTechs.reverse().forEach(function (techs, index) {
			if (techs['techId'] == tech_id) {
				notifiedTechs[index]['jobStatus'] = "tech-decline"
				notifiedTechs[index]['notifyEndAt'] = new Date();
			}
			tech_id = false;
		});
		// console.log("notifiedTechs ::: after", notifiedTechs)

		let dataToUpdate = {
			$unset: { schedule_accepted_by_technician: 1, technician: 1, schedule_accepted_on: 1 },
			schedule_accepted: false,
			notifiedTechs: notifiedTechs.reverse(),
			$push: { tech_declined_ids: user?.technician?.id }
		}
		await JobApi.updateJob(jobid, dataToUpdate)

		if (alert) {
			socket.emit("technician:schedule-job-declined", {
				"jobId": selectedJob.id,
				"technician_user": user,
				"reason": reason
			})
			// console.log(">>>>>>>>>>>>>>>>>>>>>>sending schedule job >>>>>>>>>>>>>>>>", selectedJob)
			await socket.emit("send-schedule-alerts", {
				jobId: jobid,
				accepted: false,
				customerTimezone: selectedJob?.customer?.user?.timezone,
				jobObj: selectedJob,
				primaryTime: selectedJob?.primarySchedule,
				secondryTime: selectedJob?.secondrySchedule,
				phoneNumber: selectedJob?.customer?.user?.phoneNumber,
				customerEmail: selectedJob?.customer?.user?.email,
				customerName: selectedJob?.customer?.user?.firstName,
				technicianId: false,
				decliedTechnician: user?.id
			})
			JobApi.sendSmsForScheduledDeclinedJob({ 'jobId': jobid, 'technicianName': user.firstName })
		} else {
			// console.log("not alert schedule job not found");
			await socket.emit("technician:schedule-job-declined-without-accepted", {
				"jobId": selectedJob.id,
				"technician_user": user,
				"reason": reason
			})
		}
		setLoader(false)

		setTimeout(() => {
			window.location.reload()
		}, 3000)
	}


	const pushToMeeting = async (e) => {
		e.currentTarget.disabled = true;
		e.stopPropagation();
		const job = jobData
		if (job.status == "long-job" && user && user.userType) {
		  mixpanel.identify(user.email);
		  mixpanel.track(`${user.userType} - Join long-job from dashboard`, {
			JobId: job.id,
		  });
		  window.location.href =
			process.env.REACT_APP_MEETING_PAGE +
			`/meeting/${user.userType}/${job.id}`;
		}
		if (job.status === "Accepted") {
		  mixpanel.identify(user.email);
		  mixpanel.track(`${user.userType} -Start Call from dashboard`, {
			JobId: job.id,
		  });
		  try {
			const webdata = await WebSocket.create({
			  user: user.id,
			  job: job.id,
			  socketType: "accept-job",
			  userType: user.userType,
			  hitFromCustomerSide: true,
			});
	
			job["web_socket_id"] = webdata["websocket_details"]["id"];
			await WebSocket.customer_start_call(job);
			socket.emit("job-taken-notification", job.id);
		  } catch (err) {
			console.error("pushToMeeting error in dashboard page one>>>", err);
			await WebSocket.customer_start_call(job);
		  }
		}
		
		if (user.userType === "customer") {
		  mixpanel.identify(user.email);
		  mixpanel.track("Customer - Join Call from dashboard", { JobId: job.id });
		  socket.emit("meeting-started-by-customer", { jobData: job });
		  let filter_dict = { status: "Inprogress", customer: user?.customer?.id };
		  const checkStatus = await isCompletedJob(job.id);
		  if (checkStatus) {
			openNotificationWithIcon(
			  "error",
			  "Error",
			  `Job is already completed from Technician side Please refresh your page !!`
			);
			return;
		  }
		  const findInprogressLatestJob = JobApi.findJobByParams(filter_dict);
		  findInprogressLatestJob.then(async (result) => {
			// console.log("result.data>>>>>>>>>>>>", { jobId: job.id, result });
			for (let i = 0; i < result.jobs.data.length; i++) {
			  if (user.customer.id === result?.jobs?.data[i]?.customer?.id) {
				if (job.id == result.jobs.data[i].id) {
				  try {
					const webdata = await WebSocket.create({
					  user: user.id,
					  job: job.id,
					  socketType: "accept-job",
					  userType: user.userType,
					  hitFromCustomerSide: true,
					});
	
					job["web_socket_id"] = webdata["websocket_details"]["id"];
					await WebSocket.customer_start_call(job);
				  } catch (err) {
					console.error(
					  "pushToMeeting error in dashboard page two>>>",
					  err
					);
					await WebSocket.customer_start_call(job);
				  }
				  socket.emit("invite-technician", {
					job: job.id,
					tech: job.technician,
				  });
				  get_or_set_cookie(user);
				  if (!result.jobs.data[0].GA_start_call_event_called) {
					//GA3 tag commented by Vinit on 24/04/2023.
					GAevent(
					  "Call Started",
					  "customer-start-call",
					  result?.jobs?.data[0].id,
					  user?.customer?.id
					);
					if (process.env.REACT_APP_URL) {
					  const appUrl =
						process.env?.REACT_APP_URL?.split("/")[2] || false;
					  PushUserDataToGtm("call_started", user, appUrl);
					}
					await JobApi.updateJob(result?.jobs?.data[0]?.id, {
					  GA_start_call_event_called: true,
					});
				  }
				  window.location.href =
					process.env.REACT_APP_MEETING_PAGE +
					`/meeting/customer/${job.id}`;
				} else {
				  openNotificationWithIcon(
					"error",
					"Error",
					"Looks like you are already in a meeting.Please end the meeting to start another one."
				  );
				}
				break;
			  } else {
				console.log("Customer id did not matched !!!!!");
			  }
			}
		  });
		} else {
		  let filter_dict = {
			status: "Inprogress",
			technician: user?.technician?.id,
		  };
		  const checkStatus = await isCompletedJob(job.id);
		  if (checkStatus) {
			openNotificationWithIcon(
			  "error",
			  "Error",
			  `Job is already completed from Customer side Please refresh your page !!`
			);
			return;
		  }
		  const findInprogressLatestJob = JobApi.findJobByParams(filter_dict);
		  findInprogressLatestJob.then(async (result) => {
			if (job.id == result?.jobs?.data[0]?.id) {
			  mixpanel.identify(user.email);
			  mixpanel.track("Technician - Join Call from dashboard", {
				JobId: job.id,
			  });
			  get_or_set_cookie(user);
			  if (!result.jobs.data[0]?.GA_start_call_event_called) {
				//GA3 tag commented by Vinit on 24/04/2023.
				GAevent(
				  "Call Started",
				  "tech-start-call",
				  result.jobs?.data[0]?.id,
				  user?.technician?.id
				);
				await JobApi.updateJob(result.jobs.data[0].id, {
				  GA_start_call_event_called: true,
				});
			  }
			  window.location.href =
				process.env.REACT_APP_MEETING_PAGE +
				`/meeting/technician/${job.id}`;
			} else {
			  openNotificationWithIcon(
				"error",
				"Error",
				"Looks like you are busy in another meeting. Please end the other meeting to join this one."
			  );
			}
		  });
		}
	  };

	  const isCompletedJob = async (jobId) => {
		const findJob = await JobApi.retrieveJob(jobId);
		return findJob && findJob.status === 'Completed' ? true : false;
	}
	


  return (
    <>
      {btnsToShowList?.showJobDetailsBtn && jobData ? (
        userType == "customer" ? (
          <CustomerJobsActiveBtn
            job={jobData}
            btnsToShowList={btnsToShowList}
            userType={userType}
            user={user}
            decline_job_by_technician={decline_job_by_technician}
			pushToMeeting={pushToMeeting}
          />
        ) : (
          <TechnicianJobsActiveBtn
            job={jobData}
            btnsToShowList={btnsToShowList}
            userType={userType}
            user={user}
            decline_job_by_technician={decline_job_by_technician}
			pushToMeeting={pushToMeeting}
          />
        )
      ) : <></>}
    </>
  );
});

export default ShowButtonDecider;
