import styled from "styled-components";

export const HeaderMobileDiv = styled.div`
  width: 100%;
  height: 54px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChevronLeftDiv = styled.div`
  width: 49px;
  height: 51px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
`;

export const LogoDiv = styled.div`
  width: 90px;
  height: 33px;
`;
