import React from "react";
import logo from "../../../../../assets/images/newLogoSmaller.png";

const HeadingWithLogo = () => {
  return (
    <div className="heading-with-logo-main-div">
      <img src={logo} alt="logoImage" className="help-on-its-way-logo" />
    </div>
  );
};

export default HeadingWithLogo;
