import React, { useEffect } from "react";
import { convertTime } from "../../utils";

const LongJobFeedback = ({ longJobCost, isTechnicianSubmitFeedback, job }) => {
  return (
    <>
      <tr>
        <th>Total Job Cost</th>
        <td>
          <span className="job-value">
            <>
              {" "}
              $ {longJobCost && longJobCost.totalCost}
              {isTechnicianSubmitFeedback && <>- Pending Technician Review </>}
            </>
          </span>
        </td>
      </tr>
      {job.total_subscription_seconds > 0 && (
        <tr>
          <th>Subscription time deducted</th>
          <td>
            <span className="job-value">
              <>{convertTime(job.total_subscription_seconds)}</>
            </span>
          </td>
        </tr>
      )}
    </>
  );
};

export default LongJobFeedback;
