import React, { useCallback, useEffect, useState } from "react";
import Heading from "../components/Heading";
import InputField from "../components/InputField";
import SubHeading from "../components/SubHeading";
import Button from "@mui/material/Button";
import {
  emailRegExp,
  SSO_MSG_FOR_INCORRECT_LOGIN,
} from "../../../../../constants/index";
import {
  openNotificationWithIcon,
  checkPendingStatus,
  checkAndHandleDateMatchForSchedule,
  isLiveUser,
  setCookie,
} from "utils";
import { useAuth } from "../../../../../context/authContext";
import { Spin, Modal } from "antd";
import * as UserApi from "../../../../../api/users.api";
import googleIcon from "../../../../../assets/images/google.svg";
import { useGoogleLogin } from "@react-oauth/google";
import * as CustomerApi from "../../../../../api/customers.api";
import * as AuthApi from "../../../../../api/auth.api";
import * as JobApi from "../../../../../api/job.api";
import mixpanel from "mixpanel-browser";
import { INACTIVE_ACCOUNT_STATUS_MSG } from "../../../../../constants";
import { useJob } from "../../../../../context/jobContext";
import { useHistory, useLocation } from "react-router-dom";

const InputEmail = ({
  setUserId,
  userEmail,
  setToken,
  setUserEmail,
  signUpFlowStepsObj,
  setMobileSignUpStepNumber,
  jobIdFromUrl,
  techIdFromUrl,
  job,
}) => {
  const { getGuestUser, sosUser, setSosUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [customerValid, setCustomerValid] = useState(false);
  const [loginUser, setLoginUser] = useState();
  const [latestPendingJobToUpdate, setLatestPendingJobToUpdate] = useState();
  const [scheduleMsg, setScheduleMsg] = useState(false);
  const [jobIdDecline, setJobIdDecline] = useState(false);
  const [lastPendingSoftware, setLastPendingSoftware] = useState("");
  const [pendingJobModal, setPendingJobModal] = useState(false);
  const [inProgressMessage, setInProgressMessage] = useState("");
  const [customerConfirm, setCustomerConfirm] = useState(false);
  const { updateJob } = useJob();
  const [createJobStatus, setCreateJobStatus] = useState(false);
  const [disableBackToDashbord, setDisableBackToDashbord] = useState(false);
  const history = useHistory();

  const InprogressJobMsg = pendingJobModal ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      {inProgressMessage}
    </span>
  ) : (
    <span></span>
  );
  const message = scheduleMsg ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      One of your previous job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b> is already
      scheduled with a technician. Are you sure you want to create a new job
      post?if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  ) : (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      We are still looking for a technician for your existing job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b>. Are you sure
      you want to create a new job post? if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  );

  useEffect(() => {
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userfirstName");
    sessionStorage.removeItem("ssoUserDetails");
  }, []);

  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("Email form submitted by user with email ");
    //Email format validation
    if (!emailRegExp.test(String(userEmail))) {
      openNotificationWithIcon(
        "error",
        "Invalid Email",
        `Please enter a valid email.`
      );
      setIsLoading(false);
      return;
    } else {
      const guestUserRes = await getGuestUser();
      const user = await UserApi.getUserByParamAsGuest(
        { email: userEmail.toLowerCase() },
        guestUserRes.token.accessToken
      );
     
      if (user) {
        
        if (user.userType === "customer") {
         
          // setMobileSignUpStepNumber(signUpFlowStepsObj["SignIn"])
          localStorage.setItem("userEmail", userEmail);
          localStorage.setItem("userfirstName", user.firstName);
          if (jobIdFromUrl) {
           
            if (techIdFromUrl) {
              window.location.href = `mobile-sign-up?jobId=${jobIdFromUrl}&page=SignIn&technicianId=${techIdFromUrl}`;
            } else {
              
              window.location.href = `mobile-sign-up?jobId=${jobIdFromUrl}&page=SignIn`;
            }
          } else {
            
            window.location.href = `mobile-sign-up?page=SignIn`;
          }
        } else {
          
          window.location.href = "/";
        }
      } else {
        // setMobileSignUpStepNumber(signUpFlowStepsObj["SignUp"])
        localStorage.setItem("userEmail", userEmail);
        
        if (jobIdFromUrl) {
          
          if (techIdFromUrl) {
            window.location.href = `mobile-sign-up?jobId=${jobIdFromUrl}&page=SignUp&technicianId=${techIdFromUrl}`;
          } else {
            
            window.location.href = `mobile-sign-up?jobId=${jobIdFromUrl}&page=SignUp`;
          }
        } else {
          window.location.href = `mobile-sign-up?page=SignUp`;
        }
      }
    }
  };
  const checkPendingJobs = async (loginUser, customerTypeValid) => {
    try {
      if (loginUser && loginUser?.customer) {
        const latestpendingJobs = await JobApi.latestpendingJobs({
          customer: loginUser.customer.id,
        });
        setLatestPendingJobToUpdate(latestpendingJobs);

        let pendingJobs = await checkPendingStatus(loginUser);
        if (pendingJobs.schedule_accepted) {
          setScheduleMsg(true);
        }
        setJobIdDecline(latestpendingJobs?.last_pending_job?.id);
        if (pendingJobs.success) {
          setLastPendingSoftware(pendingJobs.name);
          if (
            pendingJobs.status === "Inprogress" ||
            pendingJobs.status === "Accepted"
          ) {
            setPendingJobModal(true);
            setInProgressMessage(
              <span
                className="div-font"
                style={{ fontSize: 20, paddingTop: "40px" }}
              >
                One of your job of{" "}
                <b style={{ fontWeight: "bold" }}>{pendingJobs.name}</b> is
                already{" "}
                <b style={{ fontWeight: "bold" }}>{pendingJobs.status}</b> with
                a technician. Please complete that job first then post a new
                job.
              </span>
            );
          } else {
            setCustomerConfirm(true);
          }
        } else {
          checkCustomerHaveCard(loginUser, customerTypeValid);
        }
      }
    } catch (e) {
      console.log("Error in checkPendingJobs ", e);
    }
  };
  const checkCustomerHaveCard = async (user, customerTypeValid) => {
    try {
      
      if (user && !user?.activeStatus) {
        openNotificationWithIcon("info", "Info", INACTIVE_ACCOUNT_STATUS_MSG);
        window.location.href = "/";
      }
      if (user && user.customer) {
        mixpanel.identify(user.email);
        const ownerId = user?.ownerId;
      
        let ownerStripeId = "";
        if (ownerId) {
          const ownerUserDetails = await UserApi.getUserById(ownerId);
       
          if (ownerUserDetails?.customer?.stripe_id) {
            ownerStripeId = ownerUserDetails?.customer?.stripe_id;
          } else {
            openNotificationWithIcon(
              "info",
              "Info",
              `Please contact your organization owner to add card to proceed with this job!`
            );
            window.location.href = "/";
          }
        }

        const data = await JobApi.scheduleAcceptedJobs({
          customer: user?.customer?.id,
        });
        const primaryScheduleTimes = data.primaryScheduleTimes;
     
        // Call the function to check and handle date match
        checkAndHandleDateMatchForSchedule(
          primaryScheduleTimes,
          job?.primarySchedule,
          jobIdFromUrl,
          user
        );

        let customer_info =
          await CustomerApi.checkIfOrganisationHasSubscription({
            user: user,
            liveUser: user.customer.customerType === "live",
          });
        if (
          customer_info.has_card_or_subscription === false &&
          customerTypeValid
        ) {
          // mixpanel code//
          mixpanel.track("Customer - Ask Credit Card");
          mixpanel.people.set({
            $first_name: user.firstName,
            $last_name: user.lastName,
          });
          // mixpanel code//
          const updatedJob = await updateJob(jobIdFromUrl, {
            customer: user.customer.id,
            guestJob: false,
            cardPreAuthorization: false,
          });
         
          // setMobileSignUpStepNumber(signUpFlowStepsObj["AddCard"])
          window.location.href = `mobile?jobId=${jobIdFromUrl}&page=AddCard`;
        } else {
          const updatedJob = await updateJob(jobIdFromUrl, {
            customer: user.customer.id,
            guestJob: false,
            cardPreAuthorization: false,
          });
          
          // setMobileSignUpStepNumber(signUpFlowStepsObj["PhoneNumber"])
          window.location.href = `mobile?jobId=${jobIdFromUrl}&page=PhoneNumber`;
        }
      } 
    } catch (err) {
      console.log("error in checkForCard", err);
    }
  };
  const loginWithGoogle = useCallback(
    useGoogleLogin({
      onSuccess: async (codeResponse) => {
        takeNextStepAction(codeResponse, "customer");
      },
      onError: (errorResponse) => console.log(errorResponse),
    }),
    []
  );

  const takeNextStepAction = async (data, userType) => {
    try {
      const res = await AuthApi.loginGoogle({
        accessToken: data.access_token,
        userType,
      });

      if (res) {
        const { user, token, registered, error } = res;
        if (error && !res.success) {
          openNotificationWithIcon("error", "Error", error);
        }

        if (user && token === null && !registered) {
          sessionStorage.setItem("ssoUserDetails", JSON.stringify(user));
          localStorage.setItem("userEmail", user?.email);
          setCookie(user);
          if(jobIdFromUrl){
            window.location.href = `mobile-sign-up?page=SignUp&jobId=${jobIdFromUrl}`;
          }else{
            window.location.href = `mobile-sign-up?page=SignUp`;

          }
        } else if (user && user?.userType == "technician") {
          openNotificationWithIcon(
            "error",
            "Account Registration Error",
            SSO_MSG_FOR_INCORRECT_LOGIN
          );
          return;
        } else {
          if (user && user?.userType === "customer") {
            openNotificationWithIcon(
              "success",
              "Success",
              "User logged in successfully!"
            );
            setToken(token.accessToken);
            setUserId(user.id);
            setCookie(user);
            localStorage.removeItem("userEmail");
            sessionStorage.removeItem("ssoUserDetails");
            if (jobIdFromUrl) {
              // const customerTypeValid = (loginResponse.user.customer.customerType ? loginResponse.user.customer.customerType !== 'test' : true);
              const customerTypeValid = true;
              
              setCustomerValid(customerTypeValid);
              setLoginUser(user);
              checkPendingJobs(user, customerTypeValid);
              // checkCustomerHaveCard(loginResponse.user, customerTypeValid)
            } else {
              if (user) {
                let liveUser = await isLiveUser(user);
                let customer_info =
                  await CustomerApi.checkIfOrganisationHasSubscription({
                    user: user,
                    liveUser: liveUser,
                  });
               
                if (customer_info.has_card_or_subscription) {
                  window.location.href = `/`;
                } else {
                  window.location.href = `mobile?jobId=${jobIdFromUrl}&page=PhoneNumber`;
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.log("Error while SSO Login", error);
      openNotificationWithIcon("Something went wrong !!");
    }
  };

  const closePendingModal = () => {
    setCustomerConfirm(false);
    setPendingJobModal(false);
  };
  const backToDashBoardStatus = async () => {
    const updateJobData = {
      cardPreAuthorization: false,
      customer: loginUser?.customer?.id,
      guestJob: false,
    };
    await updateJob(jobIdFromUrl, updateJobData);
    window.location.href = "/dashboard";
  };
  const push_to_job_summary = async () => {
    setCreateJobStatus(true);
    await updateJob(jobIdDecline, { status: "Declined" });
    checkCustomerHaveCard(loginUser, customerValid);
  };

  const backToDashBoard = async () => {
    setDisableBackToDashbord(true);
    const updateJobData = {
      cardPreAuthorization: false,
      customer: loginUser?.customer?.id,
      guestJob: false,
    };
    await updateJob(jobIdFromUrl, updateJobData);
    window.location.href = "/dashboard";
  };
  return (
    <>
      <Modal
        className="get-help-now-modal"
        closable={true}
        onCancel={closePendingModal}
        visible={pendingJobModal}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              className="mobile-dsn-button-css"
              onClick={backToDashBoardStatus}
              key="no"
            >
              <span></span>Back To Dashbord
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {InprogressJobMsg}
          </span>
        </div>
      </Modal>
      <Modal
        className="get-help-now-modal"
        closable={true}
        visible={customerConfirm}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              className="mobile-dsn-button-css"
              onClick={backToDashBoard}
              key="no"
              disabled={createJobStatus || disableBackToDashbord}
            >
              {disableBackToDashbord ? (
                <Spin size="small" />
              ) : (
                <span>Back To Dashbord</span>
              )}
            </Button>

            <Button
              id="confirm-create-new"
              className="mobile-dsn-button-css"
              onClick={push_to_job_summary}
              key="yes"
              disabled={createJobStatus || disableBackToDashbord}
            >
              {createJobStatus ? (
                <Spin size="small" />
              ) : (
                <span>Create New</span>
              )}
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {message}
          </span>
        </div>
      </Modal>

      <div className="headingDiv pl-20-max-width-280 pr-20-max-width-280">
        <div className="d-flex flex-column heading-div">
          <Heading text={"Sign up / Log in"} />
          <SubHeading
            text={
              "Already have a Geeker account?  We’ll recognize your email :) "
            }
          />
        </div>
        <form onSubmit={submitForm} className="w-100percent">
          <div className="email-div">
            <InputField
              inputType={"email"}
              placeHolder={"Email Address"}
              inputDivClass={"inputDivClass"}
              inputClass={"font-nova inputClass"}
              value={userEmail}
              onChange={(e) => handleEmailChange(e)}
            />
            <div className="continueButtonDiv">
              <Button
                variant={"contained"}
                className={"continueButton"}
                onClick={(e) => submitForm(e)}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spin className="spinner spinner-pos" />
                ) : (
                  <span className="font-nova">Continue</span>
                )}
              </Button>
            </div>

            <div className="google-btn-mobile" style={{ cursor: "pointer" }} onClick={loginWithGoogle}>
              <img className="sso_image" src={googleIcon} />
              <span className="google-btn-inner" >
                Continue with Google
              </span>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default InputEmail;
