import React,{useEffect,useState} from "react";
import * as UserApi from "../../api/users.api";
const SubscriptionBasedFeedback = ({
  subscriptionCost,
  isTechnicianSubmitFeedback,
  checkForOwner,
  userIsOwner,
  ownerHaveSubscription,
  user,
  ownerInfo
}) => {
  
  const [subscriptionPendingTime, setSubscriptionPendingTime] = useState(false);
  let ownerDetails;


  useEffect(() => {
    (async() => {
        if (user?.roles[0] == "owner") {
          ownerDetails = user;
        } else {
          if (ownerInfo && ownerInfo?.customer) {
            ownerDetails = ownerInfo;
          } else {
            if (user && user?.ownerId) {
              const ownerInfoObject = await UserApi.getUserById(user.ownerId);
              ownerDetails = ownerInfoObject;
            }
          }
        }
        // console.log("ownerDetails>>>>>>>>>>>>>>>>>>",ownerDetails)
        if (ownerDetails?.customer) {
          if (ownerDetails?.customer?.subscription != undefined) {
            let time_used_in_seconds =
              ownerDetails.customer.subscription.time_used;
            let remaining_seconds;
            if (ownerDetails.customer.subscription.grand_total_seconds) {
              remaining_seconds =
                ownerDetails.customer.subscription.grand_total_seconds -
                time_used_in_seconds;
            } else {
              remaining_seconds =
                ownerDetails.customer.subscription.total_seconds -
                time_used_in_seconds;
            }
            let remaining_minutes = (remaining_seconds / 60).toFixed(2);
            let string_in_min = remaining_minutes + " min";
            let converted_format = convertTime(remaining_seconds);
            setSubscriptionPendingTime(converted_format);
          }
        }
    })();
  }, [user]);

  function convertTime(sec) {
    var hours = Math.floor(sec / 3600);
    hours >= 1 ? (sec = sec - hours * 3600) : (hours = "00");
    var min = Math.floor(sec / 60);
    min >= 1 ? (sec = sec - min * 60) : (min = "00");
    sec < 1 ? (sec = "00") : void 0;
    min.toString().length == 1 ? (min = "0" + min) : void 0;
    sec.toString().length == 1 ? (sec = "0" + sec) : void 0;
    if (hours >= 1 && hours <= 9) {
      hours = "0" + hours;
    }
    // This will check if seconds are of nan type if so then replace it with 00
    if (sec.toString() == "NaN") {
      sec = "00";
    }
    // console.log(
    //   "checking the resultant value of hh:mm:ss",
    //   hours + ":" + min + ":" + sec
    // );
    return hours + ":" + min + ":" + sec;
  }


  return (
    <>
      {checkForOwner && (userIsOwner || !ownerHaveSubscription) && (
        <tr>
          <th>Total Job Cost</th>
          <td>
            <span className="job-value">
              {/* <> $ {subscriptionCost && subscriptionCost.totalCost}</> */}
              Subscription
            </span>
          </td>
        </tr>
      )}
      {subscriptionCost.chargedWithCardAlso
        ? checkForOwner &&
          (userIsOwner || !ownerHaveSubscription) && (
            <tr>
              <th>Amount to be Paid</th>
              <td>
                <span className="job-value">
                  {subscriptionCost && subscriptionCost.promoCodeAppied ? (
                    <>
                      <strike>
                        <span className="job-value totalAmount">
                          {" "}
                          $ {subscriptionCost.amountChargedFromCard}
                        </span>
                      </strike>
                      <span className="job-value">
                        {" "}
                        $ {subscriptionCost.discountedCost}
                        {isTechnicianSubmitFeedback && (
                          <>- Pending Technician Review </>
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      {" "}
                      ${" "}
                      {subscriptionCost &&
                        subscriptionCost.amountChargedFromCard}
                      {isTechnicianSubmitFeedback && (
                        <>- Pending Technician Review </>
                      )}
                    </>
                  )}
                </span>
              </td>
            </tr>
          )
        : null}
      <tr>
        <th>Minutes used</th>
        <td>
          <span className="job-value">
            {" "}
            {subscriptionCost.subscriptionTimeDeducted
              ? subscriptionCost.subscriptionTimeDeducted
              : "00:00:00"}
          </span>
        </td>
      </tr>
      {user?.roles[0] != "user" && (
        <tr>
          <th>Remaining minutes</th>
          <td>
            <span className="job-value">
              {subscriptionPendingTime}{" "}
            </span>
          </td>
        </tr>
      )}
    </>
  );
};
export default SubscriptionBasedFeedback;
