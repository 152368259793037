import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import './ScheduleJobCancelModal.css'
import { updateJob, cancelScheduleJob } from '../../../api/job.api';
import mixpanel from 'mixpanel-browser';
import { openNotificationWithIcon, checkCustomerHaveSubscriptionMinutes } from '../../../utils';
import { useSocket } from '../../../context/socketContext';
import * as CustomerApi from '../../../api/customers.api';
import * as UserApi from '../../../api/users.api';
import * as TwilioApi from '../../../api/twilioChat.api';
import * as JobCycleApi from '../../../api/jobCycle.api';
import { JobTags } from '../../../constants/index.js';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

const ScheduleJobCancelModal = (props) => {

    const [isDisabled, setIsDisabled] = useState(false)
    const [reason, setReason] = useState('')
    const [isEmptyReason, setIsEmptyReason] = useState(false)
    const { socket } = useSocket();
    const [isWithin3HoursOrNot, setIsWithin3HoursOrNot] = useState(false)

    const handleClose = () => {
        props.setIsCancelModal(false)
        // setIsDisabled(false)
    }

    useEffect(() => {
        (async () => {
            if (props?.user?.userType === 'customer') {
                const givenTime = props?.job?.primarySchedule; // Replace this with the time you want to check
                const result = isWithin3Hours(givenTime);

                if (result) {
                    setIsWithin3HoursOrNot(true)
                } else {
                    setIsWithin3HoursOrNot(false)
                    // console.log("The given time is NOT within 3 hours from the current time.");
                }
            }
        })()
    }, [props])



    function isWithin3Hours(givenTime) {
        const currentTime = new Date().getTime();
        const parsedGivenTime = new Date(givenTime).getTime();
        const timeDifference = parsedGivenTime - currentTime;

        // Check if the time difference is within 3 hours (3 * 60 * 60 * 1000 milliseconds)
        return Math.abs(timeDifference) <= 3 * 60 * 60 * 1000 || parsedGivenTime < currentTime;
    }

    const handelCancelScheduleJob = async () => {
        await socket.emit("scheduleCancelledByCustomer", { jobId: props.cancelJobId, reason: reason });
        await updateJob(props.cancelJobId, { "status": "Declined", "schedule_accepted": false, "tech_message_dashbord": false });
        await TwilioApi.updateTwilioConversation(props.job?.twilio_chat_service?.sid);
        socket.emit("refresh-job-after-decline-by-user", props.job);
        await cancelScheduleJob(props.cancelJobId, { 'calcellationBy': props.type, 'reason': reason, 'user': props.user })
        props.setIsCancelModal(false)
        if (!isWithin3HoursOrNot) {
            openNotificationWithIcon('success', 'Success', 'Job has been cancelled.');
        }
        setTimeout(function () {
            window.location.reload()
        }, 1000)
    }


    /**
     * cancel job by tech and customer.
     * @params : we are using use state variables in this function
     * @returns: reload page after timeout
     * @author : Ridhima Dhir
     */
    const handleCancel = async (event) => {
        if (reason) {
            setIsDisabled(true)
            event.preventDefault();
            if (props.type == "Customer") {
                mixpanel.identify(props.user.email);
                mixpanel.track('Customer -  Cancelled the schedule job from dashboard', { 'JobId': props.cancelJobId });
                if (!props?.job?.schedule_accepted) {
                    await JobCycleApi.create(JobTags.CUSTOMER_DECLINED_SCHEDULE_JOB, props.cancelJobId, props.user.id);
                }
                if (isWithin3HoursOrNot && props?.job?.schedule_accepted) {
                    let striperesponce = await deducMoneyFromCustomer()
                    if (striperesponce) {
                        await handelCancelScheduleJob()
                    }
                } else {
                    // console.log("The given time is NOT within 3 else part");
                    await reFundMoney()
                    await handelCancelScheduleJob()
                }
            }

            // check type is Technician then call function decline_job_by_technician
            if (props.type == "Technician") {
                mixpanel.identify(props.user.email);
                mixpanel.track('Technician - Job declined from dashboard', { 'JobId': props.cancelJobId });
                await props.decline_job_by_technician(props.cancelJobId, true, reason)
                await JobCycleApi.create(JobTags.TECH_CANCEL_SCHEDULE_JOB, props.cancelJobId, props.user.id);

                // Update the custCancellation or techCancellation object of job as per cancellation type (customer or tech)
                //	custCancellation object update if type is Customer and push object in techCancellation field if type is tech
                // and update job
                await cancelScheduleJob(props.cancelJobId, { 'calcellationBy': props.type, 'reason': reason, 'user': props.user })

                //Modal close
                props.setIsCancelModal(false)

                //Reload current location
                setTimeout(function () {
                    window.location.reload()
                }, 1000)
            }
        } else {
            // console.log('Cancelling errrored')
            // show error message if reason is empty
            event.preventDefault();
            setIsDisabled(false)
            setIsEmptyReason(true)
        }
    };

    const deducMoneyFromCustomer = async () => {
        try {
            // This will refund the hold money from customer account

            let ownerStripeId = '';
            const ownerId = props?.job?.customer?.user?.ownerId;

            if (ownerId) {
                const ownerStripeRes = await UserApi.getUserById(ownerId);

                if (ownerStripeRes?.customer?.stripe_id) {
                    ownerStripeId = ownerStripeRes?.customer?.stripe_id;
                }
            }

            const stripeId = ownerId ? ownerStripeId : props?.job?.customer?.stripe_id;

            // Here payment_hold_id is not-applicable-here because we want to send only when we are Adding card as in that case of card no payment_hold_id is stored somewhere
            // but in normal cases like this all the data is stored in the form of object and may have more than one stripe id
            const obj = {
                payment_hold_id: "not-applicable-here",
                isDeduct: false,
                jobId: props.job?.id,
                stripe_id: stripeId
            };

            const obj2 = {
                jobId: props.job?.id,
                stripe_id: stripeId,
                isScheduleCancel: true,
            };

            let scheduleCancelResponse;

            await JobCycleApi.create(JobTags.CUSTOMER_CANCEL_ACCEPTED_SCHEDULE_JOB, props.job?.id);
            scheduleCancelResponse = await CustomerApi.deductCancelJobPayment(obj2);
            console.log('scheduleCancelResponse :: :: 1', scheduleCancelResponse)
            if (props?.job && props?.job?.customer_holded_payments && props?.job?.customer_holded_payments.length > 0) {
                await CustomerApi.deductOrRefundHoldMoney(obj);
                // console.log('scheduleCancelResponse :: :: 2', scheduleCancelResponse)
            }

            if (scheduleCancelResponse?.status === 'Successful') {
                await JobCycleApi.create(JobTags.PAYMENT_DEDUCTED_FROM_CUSTOMER, props.job?.id);
                // console.log('scheduleCancelResponse :: :: 3', scheduleCancelResponse)
                openNotificationWithIcon('success', 'Success', 'Job has been cancelled.');
                return true;
            } else {
                // console.log('scheduleCancelResponse :: :: 4', scheduleCancelResponse)
                openNotificationWithIcon('error', 'Error', scheduleCancelResponse.message);
                setIsDisabled(false)
                return false;
            }
            // console.log('scheduleCancelResponse :: :: 4',scheduleCancelResponse)

        } catch (error) {
            setIsDisabled(false)
            // Handle the error appropriately, you can log it, show an error message, or take other actions.
            console.error('Error in deducMoneyFromCustomer:', error);
            // Optionally, rethrow the error if you want it to be caught by an outer try-catch block.
            throw error;
        }
    };

    const reFundMoney = async () => {
        try {

            let ownerStripeId = '';
            const ownerId = props?.job?.customer?.user?.ownerId;

            if (ownerId) {
                const ownerStripeRes = await UserApi.getUserById(ownerId);

                if (ownerStripeRes?.customer?.stripe_id) {
                    ownerStripeId = ownerStripeRes?.customer?.stripe_id;
                }
            }

            const stripeId = ownerId ? ownerStripeId : props?.job?.customer?.stripe_id;
            const obj = {
                payment_hold_id: "not-applicable-here",
                isDeduct: false,
                jobId: props.job?.id,
                stripe_id: stripeId
            };

            if (props?.job && props?.job?.customer_holded_payments && props?.job?.customer_holded_payments.length > 0) {
                await CustomerApi.deductOrRefundHoldMoney(obj);
                return true;
            }

            return false;

        } catch (error) {
            setIsDisabled(false)
            console.error('Error in reFundMoney:', error);
            throw error;
        }
    }


    const changeReason = (e) => {
        setReason(e.target.value.trim())
        setIsEmptyReason(false)
    }

    return (
        <>
            <div>
                <Modal
                    open={props.isCancelModal}
                    onClose={() => { props.setIsCancelModal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="outer-box-modal1">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} className="close-icon-btn" style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="text-div mg-b-10">
                            <div className="end-the-job-div">
                                <span className="close-icon-btn-text font-nova">
                                    {(isWithin3HoursOrNot && props?.job?.schedule_accepted && props.type == "Customer") ? (
                                        "Please note, we offer free cancellation up to 3 hours before your meeting. However, your scheduled job is less than 3 hours away. If you cancel or reschedule now, a $24.99 fee will apply"
                                    ) : "Are you sure you want to cancel the schedule job?"}
                                </span>

                            </div>

                        </div>
                        <TextField
                            className={"text-area-reason"}
                            id="outlined-textarea"
                            label="Please tell us reason for cancellation"
                            placeholder="Placeholder"
                            multiline
                            error={isEmptyReason}
                            maxRows={4}
                            onChange={changeReason}
                            onFocus={() => setIsEmptyReason(false)}
                        />
                        <div className="d-flex justify-content-end buttons-container mg-b-10 ">
                            <div className="mg-b-10">
                                <Stack className='margin-right-16px' spacing={2} direction="row">
                                    <Button variant="outlined" className="Outlined-btn-join" onClick={() => { props.setIsCancelModal(false) }}>
                                        <span className="join-btn-css">Close</span>
                                    </Button>
                                </Stack>
                            </div>

                            <div className="mg-b-10">
                                <Stack spacing={2} direction="row">
                                    <Button variant="outlined" className="contained-end-btn" onClick={handleCancel}
                                        disabled={isDisabled}>
                                        {isDisabled ? <CircularProgress className='spin-css' /> :

                                            <span className="join-btn-css color-white" >Cancel Job</span>
                                        }
                                    </Button>
                                </Stack>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default ScheduleJobCancelModal
