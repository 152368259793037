import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./style.css";
import icon from "../../assets/icons/voteIcon.png";
import bronzeIcon from "../../assets/icons/bronzeIcon.png";
import silverIcon from "../../assets/icons/silverIcon.png";
import goldIcon from "../../assets/icons/goldIcon.png";
import { useUser } from "../../context/useContext";
import { VIP_VALUES } from "../../constants";
import { saveCustomerVipVote } from "../../api/customers.api";
import { useParams } from "react-router-dom";
import { openNotificationWithIcon } from "utils";

export default function VoteBanner() {
  const [showBanner, setShowBanner] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { user } = useUser();
  const { jobId } = useParams();

  useEffect(() => {
    if (user && user.customer && user.customer.vipVote && !user?.customer?.vipVote?.voted) {
      setShowBanner(true);
    }
  }, []);

  const handleVoteClick = (value) => async () => {
    const customerId = user?.customer.id;

    const data = {
      value,
      job_id: jobId,
    };

    setShowPopup(false);
    setShowBanner(false);

    const response = await saveCustomerVipVote(customerId, data);

    if (response?.status === "Successful") {
      openNotificationWithIcon("success", "Vote Success", "Your vote has been recorded successfully.");
    } else {
      openNotificationWithIcon("error", "Vote Failed", "Failed to record your vote. Please try again.");
    }

  };

  const handleSubscribeClick = () => {
    localStorage.setItem("CurrentStep", 10);
    window.location.href = "/customer/subscriptions";
  };

  return (
    <>
      {showBanner && (
        <div className="vote-banner" onClick={() => setShowPopup(true)}>
          <div className="vote-banner__content">
            <img
              src={icon}
              alt="vote icon"
              className="vote-banner__content__icon"
            />
            <p className="vote-banner__content__text">
              One Time Offer: Get {VIP_VALUES.meetingOffer}% off your meeting instantly with VIP!
            </p>
            <a className="vote-banner__content__link">
              UPGRADE NOW
            </a>
          </div>
        </div>
      )}

      {/* Popup */}
      <Modal show={showPopup} onHide={() => setShowPopup(false)}>
        <Modal.Header closeVariant="X">
          <Modal.Title className="popup__top__text">Coming Soon!</Modal.Title>
          <span className="popup__close" onClick={() => setShowPopup(false)}>
            X
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="popup__content">
            <div className="popup__content__title">
              <img
                src={icon}
                alt="vote icon"
                className="popup__content__icon"
              />
              <p className="popup__content__text__title">
                Enjoy free minutes + recordings with VIP
              </p>
              <p className="popup__content__text__subtitle">
                Geeker VIP subscriptions are coming! Vote for your favorite package now, and we'll give you <strong>{VIP_VALUES.voteOffer}% off a job</strong> when you upgrade on launch day! 🚀
              </p>
            </div>

            {/* Pricing table content */}
            <div className="pricing-table">
              <div className="pricing-table__item">
                <div className="pricing-table__item__title">
                  <img
                    src={bronzeIcon}
                    alt="vote icon"
                    className="pricing-table__item__icon"
                  />
                  <div className="pricing-table__item__header">Bronze</div>
                </div>
                <div className="pricing-table__item__price">${VIP_VALUES.bronzePrice} Month</div>
                <ul className="pricing-table__item__features">
                  <li><strong>{VIP_VALUES.bronzeDiscount}% off all</strong> Geeker services</li>
                  <li>+Free meeting recordings</li>
                </ul>
                <Button onClick={handleVoteClick('bronze')} className="pricing-table__item__button">
                  Vote for Bronze
                </Button>
              </div>
              <div className="pricing-table__item recommended">
                <div className="pricing-table__item__title">
                  <img
                    src={silverIcon}
                    alt="vote icon"
                    className="pricing-table__item__icon"
                  />
                  <div className="pricing-table__item__header">Silver</div>
                </div>
                <div className="pricing-table__item__price">${VIP_VALUES.silverPrice} Month</div>
                <ul className="pricing-table__item__features">
                  <li><strong>{VIP_VALUES.silverDiscount}% off all</strong> Geeker services</li>
                  <li>+Free meeting recordings</li>
                </ul>
                <Button onClick={handleVoteClick('silver')} className="pricing-table__item__button">
                  Vote for Silver
                </Button>
              </div>
              <div className="pricing-table__item">
                <div className="pricing-table__item__title">
                  <img
                    src={goldIcon}
                    alt="vote icon"
                    className="pricing-table__item__icon"
                  />
                  <div className="pricing-table__item__header">Gold</div>
                </div>
                <div className="pricing-table__item__price">${VIP_VALUES.goldPrice} Month</div>
                <ul className="pricing-table__item__features">
                  <li><strong>{VIP_VALUES.goldDiscount}% off all</strong> Geeker services</li>
                  <li>+Free meeting recordings</li>
                </ul>
                <Button onClick={handleVoteClick('gold')} className="pricing-table__item__button">
                  Vote for Gold
                </Button>
              </div>
            </div>

            {/* No Thank you link */}
            <a onClick={() => setShowPopup(false)} className="popup__content__link thanks_you">
              No Thank You
            </a>

            <a onClick={handleSubscribeClick} className="popup__content__link business_subscriptions">
              Have employees? See business subscriptions built just for you!
            </a>

            <div className="popup__content__disclaimer">
              *You will <strong>not</strong> be charged for signing up to receive notifications. You'll be notified when your subscription becomes available for purchase. <strong>Check your email for instructions.</strong>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
