import apiClient, { meetingApiClient } from "./index";
import { SECRET_KEY } from "../constants";
import { decryptResponse, decryptStripeKey, getUserIdFromCookie } from "utils";
import { isMobile } from "react-device-detect";

export async function getJobs(params) {
  const userId = getUserIdFromCookie()
  return apiClient.get(`/jobs?cuId=${userId}`, { params }).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function createJob(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/jobs?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function retrieveJob(jobId, excludeTechNotified) {
  const userId = getUserIdFromCookie()
  return apiClient
    .get(`/jobs/${jobId}?excludeTechNotified=${excludeTechNotified}&cuId=${userId}`)
    .then((response) => {
      if (response) {
        if(response?.data?.message == "Unauthorised"){
          return response.data
        }
        const decryptedData = decryptResponse(response.data)
        return decryptedData;
      }
      return Promise.reject();
    });
}

export async function updateJob(jobId, data,cuId = false) {
  const userId = cuId ? cuId :  getUserIdFromCookie()
  return apiClient.put(`/jobs/${jobId}?cuId=${userId}`, data).then((response) => {
    if (response) {
      if(response?.data?.message == "Unauthorised"){
        return response.data
      }
      const decryptedData = decryptResponse(response.data)
      return decryptedData;
    }
    return Promise.reject();
  });
}
export async function findJobByParams(
  data,
  pagination = { page: 1, pageSize: 10 }
) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/jobs/fetchjob?cuId=${userId}`, data, { params: pagination })
    .then((response) => {
      if (response) {
        if(response?.data?.message == "Unauthorised"){
          return response.data
        }
        const decryptedData = decryptResponse(response.data)
        return { jobs: decryptedData, totalPages: decryptedData.totalCount };
      }
      return Promise.reject();
    });
}

/**
 * Following API will fetch all the jobs according to the data provided without any pagination
 * @params : data
 * @return : All job list
 * @author : Vinit
 **/
export async function findAllJobsByParams(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/jobs/fetchAlljobs?cuId=${userId}`, data).then((response) => {
    if (response) {
      return { jobs: response.data, totalPages: response.data.totalCount };
    }
    return Promise.reject();
  });
}


export async function sendJobAcceptEmail(jobId) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/jobs/${jobId}/accept?cuId=${userId}`).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function createJobAsGuest(data, token) {
  const userId = getUserIdFromCookie()
  localStorage.setItem(SECRET_KEY, token);
  sessionStorage.setItem("hideHearAboutUsModal", true);
  return apiClient.post(`/jobs?cuId=${userId}`, data).then((response) => {
    localStorage.removeItem(SECRET_KEY);
    // console.log("tetch token removed from createJobAsGuest in job.api.js");
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function fetchJobAsGuest(jobId, token) {
  const userId = getUserIdFromCookie()
   localStorage.setItem(SECRET_KEY, token);
  return apiClient
    .get(`/jobs/${jobId}?excludeTechNotified=no&cuId=${userId}`)
    .then((response) => {
      if (response) {
        if(isMobile && response.data.status){
        localStorage.removeItem(SECRET_KEY);
      }
      if(response?.data?.message == "Unauthorised"){
        return response.data
      }
      const decryptedData = decryptResponse(response.data)
      return decryptedData;
      }
      return Promise.reject();
    });
}

export async function updateJobAsGuest(jobId, data) {
  const userId = getUserIdFromCookie()
  return apiClient.put(`/jobs/${jobId}?cuId=${userId}`, data).then((response) => {
    if (response) {
      if(isMobile && response.data.status){
        localStorage.removeItem(SECRET_KEY);
      }
      if(response?.data?.message == "Unauthorised"){
        return response.data
      }
      const decryptedData = decryptResponse(response.data)
      return decryptedData;
    }
    return Promise.reject();
  });
}

export async function getTotalJobs(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/jobs/totalJobs?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

/**
 * Function will find the pending jobs
 * @params = data (Type:Object)
 * @response : returns response from function
 * @author : kartar
 */

export async function getPendingJobs(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/jobs/pendingJobs?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}
export async function getInprogressJobs(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/jobs/totalInprogressJobs?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function getTotalJobsTechnician(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/jobs/totalJobsTechnician?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}
export async function getTotalJobsForTechnicianWithoutAuthenticate(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(
      "/public/totalWithoutAuthenticateJobsTechnician",
      data
    )
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

export async function getTotalPaidJobs(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/jobs/totalPaidJobs?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}


export async function checkLastJobFeedback(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`jobs/checkLastJobFeedback?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function sendTextForJobSubmission(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`jobs/sendTextForJobSubmission?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * Function will send email to  customer when technician submits long job for approval
 * @params = data (Type:Object)
 * @response : returns response from function
 * @author : Kartik
 */
export async function sendEmailForJobSubmission(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`jobs/sendEmailForJobSubmission?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * Function will send email to  technician if customer approves long job
 * @params = data (Type:Object)
 * @response : returns response from function
 * @author : Kartik
 */
export async function sendEmailForJobApproval(data) {
  const userId = getUserIdFromCookie()
 
  return apiClient
    .post(`jobs/sendEmailForJobApproval?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * Function will send email to  technician if customer rejects long job
 * @params = data (Type:Object)
 * @response : returns response from function
 * @author : Kartik
 */
export async function sendEmailForJobRejection(data) {
  const userId = getUserIdFromCookie()
 
  return apiClient
    .post(`jobs/sendEmailForJobRejection?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * Function will send sms to  customer if T declines the scheduled accepted job
 * @params = data (Type:Object)
 * @response : returns response from function
 * @author : Manibha
 */
export async function sendSmsForScheduledDeclinedJob(data) {
  const userId = getUserIdFromCookie()

  return apiClient
    .post(`jobs/sendSmsForScheduledDeclinedJob?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**xu
 * Function will get total count of pending jobs of the customer
 * @params = data (Type:Object)
 * @response : returns response from function
 * @author : Nafees
 */
export async function latestpendingJobs(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/jobs/latestpendingJobs?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}
export async function scheduleAcceptedJobs(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/jobs/scheduleAcceptedJobs?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}
export async function latestJobForCustomer(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/jobs/latestJobForCustomer?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function latestJobForCustomerTransferCase(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/jobs/latestJobForCustomerTransferCase?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * cancel schedule job by customer or tech
 * @params :
 * 		jobId(Type:String): selected Job Id,
 * 		data(Type:Object): reason, calcellationBy and current user
 * @response: calls api and decline the job by tech or customer
 * @author : Ridhima Dhir
 */
export async function cancelScheduleJob(jobId, data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/jobs/scheduleJobCalcellation/${jobId}?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * check schedule job availability by primary and secondry time.
 * @params :
 * 		jobId(Type:String): selected Job Id,
 * 		data(Type:Object): reason, calcellationBy and current user
 * @response: calls api and decline the job by tech or customer
 * @author : Ridhima Dhir
 */
export async function checkScheduleJobAvailability(jobId, data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .get(`/jobs/checkScheduleJobAvailability/${jobId}?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * pause timer when job is submitted by technician from job details page.
 * @params :
 * @response: calls api and decline the job by tech or customer
 * @author : sahil sharma
 */
export async function pauseStartLongJobTimer(data) {
  try {
    return meetingApiClient
      .post("/job/pauseLongJobTimer", data)
      .then((response) => {
        return response.data;
      });
  } catch (err) {
    console.log("error in Pause timer long job", err);
  }
}

export async function getDashboardJobsList(id,data, userId) {
  const cuId = userId ? userId : getUserIdFromCookie()
	return apiClient
	.post(`/jobs/dashboard-jobs-list/${id}?cuId=${cuId}`,data)
		.then(response => {
			if (response) {
				return response.data;
			}
			return Promise.reject();
		})
}

export async function getTwilioChatUserDetails(params) {
  return apiClient
    .post(`/twilio-chat/getTwilioUserChatDetails/`, params)
    .then((response) => {
      if (response) {
        // console.log("response :::::::::::getTwilioChatUserDetails", response);
        return response.data;
      }
      return Promise.reject();
    });
}


export async function paymentFailedJobs(customerId,rechargeStatus) {
  const userId = getUserIdFromCookie()
  const queryParams = `cuId=${userId}&rechargeStatus=${rechargeStatus}`;
  return apiClient.post(`/jobs/paymentFailedJobs/${customerId}?${queryParams}`, ).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}
export async function paymentFailedJobCustomer(customerId) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/jobs/paymentFailedJobCustomer/${customerId}?cuId=${userId}`).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}
export async function removeInternalNoteById(techNoteId) {
  const userId = getUserIdFromCookie()
	return apiClient.delete(`/jobs/removeInternalNoteById/${techNoteId}?cuId=${userId}`).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}

