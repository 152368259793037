import React from "react";
export const TermsCondition = () => {
  return (
    <div className="set_content_height">
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "18pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Geeker Terms of Use
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Effective Date 12/01/2021
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          IMPORTANT NOTICE: THESE TERMS REQUIRE BINDING ARBITRATION TO RESOLVE
          ANY DISPUTE OR CLAIM RELATING TO THESE TERMS, THE SERVICES OR
          PROPERTIES OR ANY RELATIONSHIP BETWEEN US. ANY SUCH DISPUTE OR CLAIM
          WILL BE RESOLVED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS,
          CONSOLIDATED OR REPRESENTATIVE ACTION OR ARBITRATION. THESE TERMS ALSO
          CONTAIN A DISCLAIMER OF WARRANTIES AND A DISCLAIMER OF LIABILITY, AS
          WELL AS A RELEASE AND INDEMNIFICATION BY YOU. PLEASE READ THEM
          CAREFULLY. SERVICE PROVIDERS: GEEKER IS NOT LIMITED TO ARBITRATION AS
          TO ACTS OF FRAUD OR OTHER WILLFUL MISCONDUCT BY YOU, WHICH GEEKER MAY
          ADDRESS BY ALL LAWFUL MEANS.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "16pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Part 1: Agreement to Terms
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Guide to Terms of Use.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;These terms of use have several parts:
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Part 1,&nbsp;Agreement to Terms&nbsp;and Part 2,&nbsp;General
          Terms&nbsp;apply to you in all cases, whether you are a Consumer or a
          Service Provider or other user of the Services or Platform (as those
          terms are defined below).
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Part 3,&nbsp;Additional Terms for Service Providers, contains
          additional terms applicable to home services professionals and other
          service providers who use the Services.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Agreement to Terms.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;This constitutes a legally binding contract between Geeker, Inc.
          ("Geeker," "we," "us") and you, with respect to your use of this Web
          site, our mobile application, our related telephone services and any
          other Geeker internet and digital properties (collectively, the "
          Platform "), and all services available from &nbsp;Geeker or third
          parties (but not Service Providers) in connection with the foregoing
          (collectively, the " Platform"). By accessing or using the Platform,
          you acknowledge that you have read, understood, and agree to be bound
          by these terms of use, our Privacy Policy (located here) and any
          applicable Additional Program Terms (as defined below), including any
          updates posted here or otherwise communicated to you (collectively,
          the “Terms”). If you do not agree to the Terms, please do not use the
          Platform or Services.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          References to Geeker (or “we” or “us”) in the Terms include Geeker’s
          service providers or suppliers, to the extent that such service
          providers or suppliers are acting for or on behalf of Geeker with
          respect to any aspect of the Platform. &nbsp;For the avoidance of
          doubt, this does not include “Service Providers”, all of whom act on
          their own behalf and not for or on behalf of Geeker.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Changes to Terms.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;We may modify the Terms at any time by posting a revised
          version. Any changes to the Terms will be effective immediately upon
          posting. Please review the Terms each time you use the Services as
          your continued use of the Services after such changes will constitute
          acceptance of, and agreement to, such changes. You waive any right you
          may have to receive specific notice of such changes.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Consent.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;If you are accessing and using the Platform, you affirm that you
          are at least 18 years of age or the age of majority in your
          jurisdiction, whichever is greater, and are fully able and competent
          to enter into, and comply with, the Terms. If you are accessing and
          using the Platform on behalf of a company or other legal entity, you
          represent that you have the authority to bind that entity to the
          Terms. In that situation, "you" and "your" will refer to both you and
          that entity.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Our Privacy Policy.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;Our Privacy Policy, which describes how we collect and use
          information from our users, is available here, as well as through the
          link titled "Privacy" on our homepage and other locations within the
          Platform.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Other Program Terms.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;Certain programs offered by Geeker through the Platform may have
          additional terms and conditions (the “Additional Program Terms”). If
          you elect to participate in one of these programs, Geeker will
          communicate the applicable Additional Program Terms to you. &nbsp;By
          participating in that program, you acknowledge that you have read,
          understood, and agree to be bound by, the Additional Program Terms,
          which will be deemed to be a part of the Terms.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "16pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Part 2: General Terms
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Our Services
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Services We Provide.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;Geeker connects consumers and other users of the Platform (each,
          a "Consumer") with third party providers (each, a “Service Provider”)
          of technical support services for computers, computer-peripherals and
          other devices and for software programs and applications
          (collectively, “Tech Services”). All Tech Services through the
          Platform are provided on a remote basis through telephone, inernet or
          other means of digital communication. Tech Services do not presently
          include hardware repair services. &nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Geeker facilitates communication and transactions between Consumers
          and Service Providers. Through the Platform, Consumers can access
          Service Providers and Service Providers can make their Tech Services
          available to Consumers, on-demand or at scheduled times in response to
          Consumer requests or inquiries. &nbsp;Geeker does not provide Tech
          Services.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          No Responsibility, Endorsement or Guarantee for Service Providers;
          Estimates.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;Geeker does not deliver, and is not responsible for, any Service
          Provider’s services or advice. Geeker is not an agent of any Service
          Provider, and Service Providers are not employees or agents of Geeker.
          Geeker does not sponsor, endorse, recommend or approve any Service
          Provider. While we try to confirm that Service Providers meet certain
          requirements, we cannot and do not represent or warrant that any
          Service Provider is licensed, qualified, insured or capable of
          performing any service. We do not make any guarantees, warranties or
          representations of any kind regarding any Service Provider or any
          services or advice of any Service Provider, and we will not be
          responsible for any action or inaction of any Service Provider. Any
          reference to or use by any Service Provider of any aspect of the
          Platform, or of trademarks or other brands or names of Geeker, does
          not in any way change the disclaimers by Geeker regarding Service
          Providers as described in this paragraph. Reference to any products,
          services or other information by trademark, manufacturer, supplier or
          otherwise does not constitute or imply an endorsement, sponsorship or
          recommendation by us. We do not guarantee that the Platform can match
          a Consumer’s service needs with a Service Provider or that Service
          Providers capable of and willing to meet a Consumer’s service needs
          will be available as and when needed. Any quote or estimate provided
          in connection with a request for Tech Services is only a guide, not a
          contractually binding offer, and is not a guarantee of the actual cost
          of your specific project.&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Accounts and Charges for Consumers
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          User Account.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;Account registration is required for Consumers to receive or use
          aspects of the Platform including access to Tech Services. When you
          open or confirm an account, you will be required provide us with
          specified personal information, which may include your name, address,
          mobile phone number, and (if applicable) credit card payment details.
          You must keep your account information complete, accurate and current
          at all times. You agree to maintain the security of your account
          information and that you are responsible for all activity in
          connection with your account. By creating your account, you agree that
          communication may be made with you via telephone, email, text (SMS)
          message or other reasonable electronic or non-electronic means, at any
          of the contact numbers or addresses you provide, as part of the normal
          business operation of the Services.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Charges.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;Fees and other charges (including any cancellation fees) for
          Tech Services (“Charges”) are listed on the Platform. You will be
          informed of Charges before Tech Services are provided. &nbsp;When
          Service Providers perform Tech Services for you, you will be charged
          at the listed rates for the Tech Services they provide. . &nbsp;Any
          information provided by Geeker regarding promotional offers, discounts
          or other terms relating to Charges is solely for informational
          purposes. &nbsp; &nbsp;Applicable sales tax will be added to all
          Charges where required by law.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Payment. &nbsp;
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp; Charges are immediately due and payable upon provision of the
          applicable Tech Services. All payments are required to be made by
          credit card and will be processed by Geeker (on behalf of the
          applicable Service Providers) using Stripe or such other third party
          payment processing vendor(s) as Geeker may select. &nbsp;You authorize
          Geeker (and such other vendors) to charge any on-file credit card or
          other previously approved methods of payment to pay all Charges.
          &nbsp;You represent and warrant that the credit card information you
          provide is accurate and that you are authorized to use it to pay the
          Charges. You agree not to initiate a chargeback with your credit card
          issuer unless and until you have exhausted all attempts to resolve any
          disputes directly with Geeker, and you understand that initiating a
          chargeback does not extinguish your obligation to pay. &nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Refunds; Credits
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          . All payments by Consumers are final and non-refundable. Geeker may,
          however, in limited circumstances and in its sole and absolute
          discretion, elect to provide a refund or credit toward future
          services. Any payment which Geeker has elected to refund will be
          refunded back to the original method of payment. If you paid with a
          credit card, we will make the refund only to the card you used for the
          payment, not another card. If the card used to make the payment is
          inactive or has been cancelled, the card issuer should apply the
          refund to your new card or send you a refund. Please contact your card
          issuer for more information.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          No Interest.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;You acknowledge and agree that funds in your account within the
          Platform will accrue no interest and you are entitled to no interest
          earned or otherwise resulting from balances in any accounts within the
          Platform.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Inactive Accounts.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;If a User account remains inactive for one year or more, Geeker
          reserves the right to terminate the account, including any credits,
          incentives, rewards or the like associated with the account.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Consent for Communications
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Telephone Calls and Other Communications.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;When you contact us, when we contact you, or when you
          communicate with a Service Provider through the Platform (including
          when we connect calls between Consumers and Service Providers), we may
          monitor and/or record those communications for quality assurance,
          customer satisfaction and other purposes. Calls between Service
          Providers and Consumers that originate with a partner service may also
          be monitored and recorded. You consent to this monitoring and
          recording.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Your Consent to Receive Automated Calls/Texts.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;By providing your telephone number(s), you consent to receive
          autodialed, pre-recorded or artificial voice calls and text (SMS)
          messages:
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "10pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              if you are a Consumer, by Geeker, Service Providers and other
              companies that help connect you to Service Providers about your
              project and other services and products that may be of interest to
              you; and
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "10pt",
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              if you are a Service Provider, by Geeker and other companies that
              help connect you with Consumers.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Agreeing to receive marketing calls or text (SMS) messages is not a
          condition of purchasing any goods or services.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We may also contact you for servicing your account, addressing
          complaints, billing or other account-related matters, or other
          purposes reasonably related to the Services.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You agree that automated calls or text (SMS) messages may be made to
          your telephone number(s) even if your number(s) is registered on any
          state or federal Do Not Call list. You agree to be contacted at any
          email or mailing address or telephone number that you or anyone acting
          on your behalf provides to Geeker or that we obtain through other
          lawful means. For complete details on our use of your information,
          including Collected Information (as defined below), and how to opt out
          of receiving Geeker marketing communications, please see our Privacy
          Policy (located&nbsp;here).
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Permissible Use
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Grant of Limited License to Consumers.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;We hereby grant you, as a Consumer, a limited, revocable,
          non-exclusive, non-transferable and non-sublicensable right to access
          and use the Platform for your personal, non-commercial (unless
          otherwise agreed by us) and informational use only, subject to your
          compliance with the Terms.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Grant of Limited License to Service Providers.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;We hereby grant you, as a Service Provider acting in your
          professional capacity, a limited, revocable, non-exclusive,
          non-transferable and non-sublicensable right to access and use the
          Platform for the purpose of providing information and Tech Services to
          Consumers through the Platform, subject to your compliance with the
          Terms.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          The Platform and Geeker’s Marks are Protected.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;The Platform, including all materials, content and information
          therein and their selection, arrangement and composition, and all
          trademarks, service marks or other brands or names of Geeker
          (collectively, the "Content"), are the proprietary property of Geeker
          and its suppliers and licensors and are protected by United States and
          international intellectual property laws, including trademark and
          copyright laws. You agree not to remove, alter or obscure any
          copyright, trademark or proprietary rights notice incorporated in the
          Content or accompanying the Services. Except for the limited license
          explicitly granted to you under the Terms, no other license is granted
          to you, whether by implication, estoppel or otherwise and all rights
          are explicitly reserved.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          No Reverse Engineering.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;You may not reverse engineer, decompile, disassemble or
          otherwise attempt to derive the source code or architectural framework
          for the Platform (except to the extent specifically permitted by
          applicable law).
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          No Automated Queries; No Harvesting.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;You may not access, download, monitor, or copy any Content
          contained on or in any aspect of the Platform through automated or
          artificial means (including screen and database scraping, spiders,
          robots, crawlers, deep-link, or any similar or equivalent automatic or
          manual process), or in any way obtain or attempt to obtain any Content
          through any means that Geeker does not intentionally make available
          through the Platform. However, general purpose Internet search engines
          and non-commercial public archives that use tools to gather
          information for the sole purpose of displaying hyperlinks to the
          Platform are granted a limited exception from the foregoing exclusion,
          provided that they do so from a stable IP address or range of IP
          addresses using an easily-identifiable agent that adheres to all
          limitations set forth in any applicable robots.txt file. You may not
          harvest information about Consumers or Service Providers for any
          purpose.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          No Improper Uses.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;You may not frame any part of the Platform. You may not use, or
          attempt to use, the Platform through any means not explicitly and
          intentionally made available, provided or intended with respect to the
          Platform (including attempting to gain unauthorized access to any
          portion of the Platform, the related services, or any other systems
          connected to the Platform and related services). You may not use or
          access the Platform or related services in any manner that could
          damage, disable, overburden, or impair any aspect of the Platform or
          that could interfere with the rights of or otherwise harm Geeker, our
          service providers or suppliers, Service Providers, other Consumers or
          any other person.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          No Objectionable Conduct.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;You agree that, in using the Platform and interacting with
          Geeker, Service Providers and/or Consumers (as the case may be), you
          will not (i) engage in any form of harassment or offensive behavior,
          including making abusive, threatening, defamatory, racist, obscene, or
          offensive statements; (ii) engage in deceptive or fraudulent behavior;
          (iii) infringe or violate the privacy rights, property rights or any
          other rights of any person or entity; or (iv) violate any applicable
          law, rule or regulation.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Account Information; Platform Modification and Access &nbsp;&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Accurate Information.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;If you are a Consumer, you will be asked to disclose certain
          information about yourself and your service requirements when you
          submit a request for Tech Services and/or when you open or confirm
          your Geeker account. You agree to provide us with accurate, complete
          and up to date information and to keep it updated, complete and
          correct. You verify that you are the current subscriber or owner of
          any telephone number that you provide. You are strictly prohibited
          from providing a telephone number that is not your own. If we discover
          that any information provided in connection with your registration is
          false or no longer accurate, we may suspend or terminate your account
          at any time if you do not correct or update such information.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Modification of Platform Services.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;We reserve the right to modify, restrict access to, or
          discontinue the Platform (or any portion of the related services),
          temporarily or permanently, with or without notice to you, and we are
          not obligated to support or update the Platform or related services.
          Unless explicitly stated otherwise, any new features that augment or
          enhance the current Platform or related services shall be subject to
          these Terms.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Termination; No Right to Platform Access.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;We reserve the right to refuse Platform access to anyone. We
          reserve the right, with or without notice, and in our sole discretion,
          to terminate any license to the Platform and related services, in
          whole or in part, and to terminate, limit or prevent any Consumer’s or
          Service Provider’s future access to and use of the Platform and
          related services, for any at any time and for any reason (including
          those specifically described in these Terms).
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          REMOTE ACCESS TO YOUR COMPUTER
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          In rendering Tech Services, Service Providers may need to access your
          computer or other device remotely through the internet. You authorize
          Service Providers to remotely access and control your computer and
          other devices for the purpose of rendering such services. Without
          limiting the foregoing, Service Providers may remotely connect to your
          computer or other devices, download and use software on your computer
          or other devices to gather system data, repair your computer or other
          devices, take remote control of your computer or other devise and
          change the settings on your computer or other devices. Other than as
          set forth in the warranty section below, you agree that Geeker has no
          responsibility or liability under any circumstance at any time for any
          loss or harm to your computer or other devices, data or otherwise that
          may arise from or may be related to the foregoing.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Data Backup
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          . &nbsp;YOU ARE SOLELY RESPONSIBLE FOR MAINTAINING AND BACKING UP ALL
          INFORMATION, DATA, TEXT OR OTHER MATERIALS (COLLECTIVELY “CUSTOMER
          DATA”) AND SOFTWARE STORED ON YOUR COMPUTER AND STORAGE MEDIA BEFORE
          ALLOWING ACCESS TO YOUR COMPUTER OR OTHER DEVICE BY SERVICE PROVIDERS.
          YOU ACKNOWLEDGE AND AGREE THAT NEITHER GEEKER NOR SERVICE PROVIDERS
          HAVE ANY RESPONSIBILITY OR LIABILITY UNDER ANY CIRCUMSTANCE AT ANY
          TIME FOR ANY LOSS OR CORRUPTION OF CUSTOMER DATA, SOFTWARE OR HARDWARE
          THAT MAY ARISE OUT OF THE TECH SERVICES. SERVICE PROVIDERS ARE NOT
          PERMITTED TO PROVIDE BACKUP COPIES OR SUPPORT INSTALLATION OF
          UNLICENSED SOFTWARE TO CUSTOMERS. PLEASE ENSURE THAT YOU HAVE A
          LICENSED COPY OF ALL NECESSARY SOFTWARE.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Neither Geeker nor Service Providers take any responsibility for any
          data that could be on any hard drives, memory drives, peripherals or
          any other device and you release such persons from any and all
          liability for any loss of data, whether it be by accident or intended.
          If necessary, Service Providers may delete some or all files (data and
          software programs) in an attempt to improve the functionality of your
          computer or device.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Use of Software and Tools
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          . &nbsp;Service Providers may need to download and/or run software on
          your computer or other device to help diagnose and resolve a
          technology issue. Service Providers use several types of software: the
          first type provides computer system information to Service Providers
          which helps diagnose and resolve technology issues, the second type
          allows Service Providers to remotely control your computer or other
          device and modify its settings or software, and the third type
          generally consists of utilities and other tools to improve computer
          performance and help resolve technology issues.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You acknowledge and agree that use of all MyTechWiz&nbsp;LLC software
          and third party&nbsp;software and tools (accessed, downloaded or
          otherwise provided or made available in connection with Tech Services
          (collectively “Software”) are subject to the license agreements that
          may appear or be referenced when you access or download the Software.
          You may not access, download or use any Software without agreeing to
          the terms and conditions of the license agreements without
          modification. You agree that Service Providers may download and
          utilize Software from third party&nbsp;web sites and accept any
          applicable license agreements on your behalf. You acknowledge and
          agree that Service Providers may download and install trial versions
          of Software that will expire and cease to function after a certain
          period of time (usually thirty days) unless you purchase a license to
          continue using such Software. You agree that Service Providers may,
          but are not obligated to, remove any Software downloaded to your
          computer while rendering Tech Services upon completion of such Tech
          Services.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Customer Responsibilities
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          . &nbsp;You agree to reasonably cooperate with Geeker and Service
          Providers as necessary to ensure any Tech Services requested through
          the Platform are provided in a timely manner. &nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Monitoring of Platform Communications
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          .
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;&nbsp;
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We may, but have no obligation to, monitor and record interactions and
          communications of Consumers and Service Providers on the Platform,
          including telephone calls and online sessions (which may include
          images of your computer screen or other device) for purposes of
          improving customer service, internal training and internal market
          research. You acknowledge and agree that we are authorized to monitor
          and record such activities and to disclose any information as
          necessary or appropriate to satisfy any law, regulation or other
          governmental request, to operate the Platform properly, and to protect
          ourselves and/or Consumers and Service Providers. Please see our
          Privacy Policy for further details.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          User Submissions and Other Collected Information
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Collected Data Definition.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;You agree and acknowledge that Geeker, Service Providers and
          Consumers may collect data, text, photos, videos, measurements,
          comments, reviews, feedback, notes, ideas, know-how, techniques, or
          other information (individually and collectively, "Information")
          before, during, and after the provision of Tech Services or Platform
          services, or otherwise in connection with Tech Services or Platform
          services (individually and collectively, "Collected
          Information").&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You Are Responsible for User Submissions.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;The Platform may permit you and other Consumers and Service
          Providers to submit Information (individually and collectively, "User
          Submissions"). The person submitting a User Submission, and not
          Geeker, is solely responsible for that person’s User Submission and
          the consequences of submitting it. By way of example and not
          limitation, if a Service Provider provides you with advice or other
          information, the Service Provider (and not Geeker) is solely
          responsible for that advice and information. You acknowledge and agree
          that Geeker has no obligation to pre-screen your or any other person’s
          User Submissions.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Knowingly submitting false names or contact information, or not
          keeping your contact information updated, not only wastes everybody’s
          time, it could result in harm to consumers (including violations of
          privacy) and significant legal and regulatory liability, damages,
          penalties and costs for Geeker and our service providers and
          partners.&nbsp;
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Accordingly, if you knowingly input false information in connection
          with &nbsp;a service request, including any name, telephone number or
          other contact information that is not yours (whether it’s someone
          else’s or just made up), or if you fail to keep the information in
          your Geeker &nbsp;account current and correct, you agree to fully
          indemnify and be liable to Geeker and each Service Provider or other
          party who accepts that service request for any claims (including
          claims under the Federal Telephone Consumer Protection Act or its
          state law equivalents), losses, liabilities, damages (direct,
          punitive, consequential or other), fines, penalties, and costs and
          expenses (including reasonable attorneys’ fees) incurred by the
          affected parties in connection with the false or incorrect
          information. We have the exclusive right to choose counsel, at your
          expense, to defend any such claims.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Limited Disclosure of User Submissions.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp; You further acknowledge and agree that the description of the
          project for which you request Tech Services, all information contained
          in any reviews of Service Providers that you submit and any photos
          that you upload may be viewed by the general public and will not be
          treated as proprietary or confidential. Please be careful what you
          post, to avoid inadvertently disclosing your address or any other
          personal information that you do not wish to be seen by the general
          public.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          License to User Submissions.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;With respect to both User Submissions and Collected Information,
          you grant Geeker a worldwide, non-exclusive, royalty-free, fully paid,
          perpetual, irrevocable, and fully sub-licensable and transferable
          license to use, reproduce, distribute, prepare derivative works of,
          publish, broadcast, publicly perform and display, make, use, import,
          offer to sell, sell, and otherwise transfer and exploit all
          copyrights, inventions, and other intellectual property rights in the
          User Submissions and Collected Information in any form and any medium,
          subject to our obligations in the Privacy Policy relating to personal
          information. We will not pay you or otherwise compensate you for User
          Submissions or Collected Information. You represent that you are the
          exclusive owner of all rights in the User Submissions that you submit.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Do Not Post Illegal, Harmful, or Prohibited Content.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;You agree not to post, submit or link to any User Submissions or
          material that (i) is prohibited under the section above entitled
          Permissible Use/No Objectionable Conduct; (ii) directly or indirectly
          directs persons to another online or offline location that provides
          products or services similar to the Platform services or Tech
          Services; (iii) involves unsolicited or unauthorized advertising,
          promotional materials, junk mail, spam, chain letters, pyramid
          schemes, or any other form of solicitation; or (iv) facilitates
          gambling, gaming, raffles, lotteries, sweepstakes, and/or any other
          activity featuring the award of a prize.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Reviews of Service Providers.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;You may only post a review of a Service Provider if you have
          first-hand knowledge of the services provided by such Service Provider
          (that is, such Service Provider provided Tech Services to you). You
          may not accept anything of value from a Service Provider in exchange
          for posting a review. You may not post or submit a review of a Service
          Provider if you are (i) an employee, contractor, partner of, or
          otherwise affiliated with, such Service Provider, (ii) an employee,
          contractor, partner of, or otherwise affiliated with, a competitor of
          such Service Provider, or (iii) related to such Service Provider,
          including by blood, adoption or marriage. You agree that any review
          that you post or submit will be honest and factually accurate.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Review of User Submissions.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;We do not approve, control or endorse your or anyone else's User
          Submissions and have no obligation to do so. However, we reserve the
          right (but assume no obligation) to pre-screen, refuse, remove or
          modify User Submissions for any reason, at our sole discretion.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Suggestions.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;If you elect to provide or make available to Geeker any
          suggestions, comments, ideas, improvements or other feedback relating
          to the Platform, Tech Services or otherwise (“Suggestions”), Geeker
          will be free to use, disclose, reproduce, have made, modify, license,
          transfer and otherwise utilize and distribute your Suggestions in any
          manner, without credit or compensation to you.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Accuracy of Information.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;Geeker makes no representation or warranty about the accuracy or
          suitability of the User Submissions or other information displayed on
          the Platform or provided through Platform services. We use
          commercially reasonable efforts to promptly update any incorrect
          information displayed on the Platform, when we are notified or
          otherwise become aware of such inaccuracy. We do not have any
          obligation to contact you to update or correct any information
          previously accessed by or provided to you through the Platform or
          related services.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Third-Party Intellectual Property
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Third Party Materials and Intellectual Property.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;The Platform may contain links to third party websites,
          advertisements, services, offers, activities or other content
          (collectively, "Third-Party Materials"), and Third-Party Materials may
          be provided on the Platform as part of the Platform services. These
          Third-Party Materials are not owned or controlled by Geeker. These
          Third-Party Materials are provided for your reference and convenience
          only, and do not imply any endorsement, sponsorship or recommendation
          by Geeker. All non- Geeker trademarks, product names and logos
          appearing on our Platform are property of their respective owners.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Claims of Copyright Infringement.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;If you believe that your work has been reproduced in connection
          with the Platform or related services in a manner that constitutes
          copyright infringement, you may submit a notification pursuant to the
          Digital Millennium Copyright Act ("DMCA") by sending a written
          notification to Geeker at the address below, with the following
          information: (i) a physical or electronic signature of the person
          authorized to act on behalf of the owner of the copyright interest,
          (ii) identification of the copyrighted work claimed to have been
          infringed, (iii) a description of where the material that you claim is
          infringing is located within the Platform or related services, (iv)
          information reasonably sufficient to permit us to contact you, such as
          an address, telephone number, and, if available, an e-mail address,
          (v) a signed statement that you have a good faith belief that use of
          the material in the manner complained of is not authorized by the
          copyright owner, its agent, or the law, and (vi) a signed statement
          that the information in the notification is accurate, and under
          penalty of perjury, that you are the copyright owner or you are
          authorized to act on behalf of the owner.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Notification pursuant to the DMCA should be submitted to:
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Geeker LLC.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          <br />
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Attn: Legal Department 6 Melnic Dr Monsey NY 10952
          (800)-371-4497&nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "16pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Part 3: Additional Important Legal Terms
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Warranty Disclaimer
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          The Platform is provided on an "as is" basis without warranties of any
          kind, either express or implied. To the fullest extent permitted by
          law, the Geeker Parties disclaim all warranties, express, implied,
          statutory, and otherwise, in connection with the Platform and your use
          of or transactions with Geeker, Consumers, Service Providers or other
          third parties, including any implied warranties of title,
          merchantability, fitness for a particular purpose, satisfactory
          quality, security, accuracy, availability, use of reasonable care and
          skill, and non-infringement, as well as warranties arising by usage of
          trade, course of dealing, and course of performance.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Limitation of Liability
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          In no event will the Geeker Parties be liable to you or any third
          party for any cost of cover, lost profits or direct, indirect,
          incidental, special, punitive, or consequential damages whatsoever
          arising out of, based on, or resulting from your use of the Platform
          and your use of, or transactions with, Geeker, Consumers, Service
          Providers or other third parties, whether based on warranty, contract,
          tort, negligence or any other legal theory, whether or not such
          damages are foreseeable and whether or not Geeker is advised of the
          possibility of such damages. The foregoing limitation of liability
          shall apply to the fullest extent permitted by law in the applicable
          jurisdiction.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          In no event will the collective liability of the Geeker Parties
          arising out of or in connection with the Terms, the Platform , and
          your use of, or transactions with, Geeker, Consumers, Service
          Providers or other third parties exceed the lesser of (a) the amount
          you have paid to Geeker for use of the Platform in the preceding
          6-month period or (b) $250.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Any claims relating to the Services or the Terms must be brought
          within one (1) year from the date the cause of action arose. Claims
          brought after such period will be VOID.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Indemnification
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          In addition to your indemnification obligations with respect to false
          or incorrect information (described above), you agree to indemnify,
          defend and hold harmless the Geeker Parties from and against any
          claims, disputes, demands, losses, obligations, liabilities, expenses,
          damages and costs (including attorneys’ fees) due to or arising out of
          (2) your transactions with Consumers, Service Providers or other third
          parties, or (3) any violation by you of any of the Terms or applicable
          laws. Geeker reserves the right, at its own cost and sole discretion,
          to assume the exclusive defense and control of any matter otherwise
          subject to indemnification by you, in which event you will fully
          cooperate with Geeker in asserting any available defenses.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Release
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You agree that you bear all risk and, in consideration of your access
          to and use of the Platofrm, you hereby release Geeker, its affiliates,
          and their respective officers, directors, employees, contractors,
          shareholders, agents, representatives, licensors, licensees and
          suppliers (collectively, the "Geeker Parties") from all claims,
          demands, liabilities and damages (actual and consequential) of every
          kind and nature, known and unknown, suspected and unsuspected,
          disclosed and undisclosed, now and in the future, arising out of or in
          any way connected with your use of the Platform and your use of, or
          transactions with, Geeker, Consumers, Service Providers or other third
          parties (including any claims under the federal Telephone Consumer
          Protection Act, Truth In Caller ID Act or Telemarketing Sales Rule and
          any state equivalents, and any federal or state tort or consumer
          protection laws). You further waive any and all rights and benefits
          otherwise conferred by any statutory or non-statutory law of any
          jurisdiction that would purport to limit the scope of a release or
          waiver. You waive and relinquish all rights and benefits which you
          have or may have under the statutory or non-statutory law of any
          jurisdiction relating to the release of claims, rights or benefits of
          which you may be unaware, to the full extent that you may lawfully
          waive all such claims, rights and benefits.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Disputes
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Binding Arbitration.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;You agree that any dispute or claim arising out of or relating
          in any way to the Terms, your access to or use of any aspect of the
          Platform or any relationship between us, including the validity,
          applicability or interpretation of the Terms (any of these, a
          “Dispute”) will be resolved by binding arbitration rather than in
          court. There is no judge or jury in arbitration, and court review of
          an arbitration award is limited.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          The arbitration will be conducted by the American Arbitration
          Association (AAA) under its then-applicable rules, including (as
          appropriate) its Supplementary Procedures for Consumer-Related
          Disputes. The AAA's rules are available at http://www.adr.org/.
          Payment of all filing, administration and arbitrator fees will be
          governed by the AAA’s rules. The arbitration shall be conducted in the
          English language by a single independent and neutral arbitrator. For
          any hearing conducted in person as part of the arbitration, you agree
          that the hearing will be conducted in Westchester County, New York or
          the city within the United States in which you reside. Without
          limiting the jurisdiction of any other court, you admit and further
          irrevocably agree to submit to the personal jurisdiction of the courts
          located within Westchester County, New York for the purpose of
          entering judgments on arbitral awards.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Class Action Waiver.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;Any Dispute will be conducted only on an individual basis and
          not in a class, consolidated or representative action or arbitration
          or as a named or unnamed member in a class, consolidated,
          representative or private attorney general legal action. Your access
          and continued use of the Properties and/or the Services signifies your
          explicit consent to this waiver.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Venue.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;If for any reason a claim proceeds in court rather than in
          arbitration, we each waive any right to a jury trial and agree that
          such claim shall be brought only in courts of competent jurisdiction
          located in Westchester County, New York. You hereby submit to the
          personal jurisdiction and venue of such courts and waive any objection
          on the grounds of venue, forum non conveniens or any similar grounds
          with respect to any such proceeding.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Notwithstanding anything to the contrary, you and Geeker may seek
          injunctive relief and any other equitable remedies from any court of
          competent jurisdiction to protect intellectual property rights,
          whether in aid of, pending or independently of the resolution of any
          Dispute pursuant to the arbitration procedures set forth above.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Governing Law.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;You agree that the Terms and any Dispute will be governed solely
          by United States and New York state law, without regard to conflict of
          law provisions.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          A Consumer’s rights under an agreement with a Service Provider will be
          governed by the terms of that agreement and by applicable federal,
          state, and local laws.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Attorneys’ Fees.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;If any of the Geeker Parties take legal action against you as a
          result of your violation of these Terms, the GeekerParties will be
          entitled to recover from you, and you agree to pay, all reasonable
          attorneys' fees and costs of such action, in addition to any other
          relief granted to the Porch Parties.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "13.999999999999998pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          General
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Construction.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;All references to “dollars” or “$” in the Terms or on the
          Properties refer to United States dollars. Unless otherwise indicated,
          the words “include,” “includes” and “including,” when used in the
          Terms, shall be deemed in each case to be followed by the words
          “without limitation.”
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Assignment.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;The Terms, and any rights and licenses granted hereunder, may be
          transferred or assigned by you only with Geeker's prior written
          consent, but may be assigned by Geeker without restriction and without
          notice to you.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Entire Agreement and Severability.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;The Terms (which, as noted above, include any applicable
          Additional Program Terms) constitute the entire agreement between you
          and Geeker concerning the Services and supersede all prior or
          contemporaneous communications of any kind between you and Geeker with
          respect to the Services. If any provision of these Terms is held by a
          court or other tribunal of competent jurisdiction to be invalid, void,
          or unenforceable, such provision shall be limited or eliminated to the
          minimum extent necessary and replaced with a valid provision that best
          embodies the intent of these Terms, and the remainder of these Terms
          shall remain in full force and effect.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Waiver.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;No waiver of any provision of these Terms shall be deemed a
          further or continuing waiver of such term or any other term, and
          Geeker's failure to assert any right or provision under these Terms
          shall not constitute a waiver of such right or provision.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Third Party Beneficiaries.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;Nothing in the Terms create any right of action on the part of
          any third party, except (i) for the Geeker Parties and suppliers or
          others who are party to written agreements with us explicitly giving
          them third party beneficiary rights and (ii) as expressly provided in
          the applicable Additional Program Terms.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Export Control.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;Software and the transmission of applicable technical data, if
          any, in connection with the Services may be subject to export
          controls. You agree to comply with all applicable laws regarding
          software and the transmission of technical data exported from the
          United States or the country in which you reside.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Contact Us.
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Calibri,sans-serif",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;If you have questions about these Terms, please contact us by
          email at info@geeker.co
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </div>
  );
};
