import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Col, Modal, Row, Spin, Typography } from "antd";
import * as TwilioApi from "api/twilioChat.api";
import ChatPanelTwilio from "components/ChatPanelTwilio";
import BasicButton from "components/common/Button/BasicButton";
import { getTimezone } from "countries-and-timezones";
import $ from "jquery";
import mixpanel from "mixpanel-browser";
import HeadingWithLogo from "pages/Customer/ProfileSetup/Components/HeadingWithLogo";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import PhoneInput from "react-phone-input-2";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { useHistory, useParams } from "react-router";
import * as CustomerApi from "../../../../api/customers.api";
import * as JobApi from "../../../../api/job.api";
import * as JobCycleApi from "../../../../api/jobCycle.api";
import * as UserApi from "../../../../api/users.api";
import * as WebSocket from "../../../../api/webSocket.api";
import computerIcon from "../../../../assets/images/Computer.png";
import happyBoy from "../../../../assets/images/happyBoy.png";
import callIcon from "../../../../assets/images/phone-incoming.svg";
import notifySound from "../../../../assets/sounds/notification.mp3";
import profileImg from "../../../../assets/users/user.png";
import Loader from "../../../../components/Loader";
import { JobTags } from "../../../../constants/index.js";
import { useJob } from "../../../../context/jobContext";
import { useNotifications } from "../../../../context/notificationContext";
import { useSocket } from "../../../../context/socketContext";
import "../../../../style.css";
import {
  GAevent,
  PushUserDataToGtm, 
  deleteParticipantForKeepSearching,
  get_or_set_cookie,
  openNotificationWithIcon,
  getCookie
} from "../../../../utils";
const { Text } = Typography;
function GeekHasBeenFound({ user, job }) {
  // console.log("job>>>>>>>>>>>", job);
  const [error, setError] = useState({});
  const { fetchJob, updateJob, getTotalJobsForTechnicianWithoutAuthenticate } =
    useJob();
  const { jobId } = useParams();
  const { updateReadStatus } = useNotifications();
  const [method, setMethod] = useState("ComputerAudio");
  const { socket } = useSocket();
  const history = useHistory();
  const [phoneNum, setPhoneNum] = useState(0);
  const [phoneNumUi, setPhoneNumUi] = useState(0);
  const [EditPhoneNum, setEditPhone] = useState(0);
  const [showLine, setShowLine] = useState(false);
  const [showeditor, setShowEditor] = useState(false);
  const [customerId, setCustomerId] = useState();
  const pattern = new RegExp(/^\+\d[0-9\b]+$/);
  const DATE_OPTIONS = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: user.timezone,
  };
  const [isLoading, setIsLoading] = useState(true);
  const [startCallDisable, setStartCallDisable] = useState(false);
  const [startCallDisablePhone, setStartCallDisablePhone] = useState(false);
  const [declineCallDisable, setDeclineCallDisable] = useState(false);
  const [techRating, setTechRating] = useState("5.00");
  const [jobMethodType, setJobMethodType] = useState("");
  const [callAlreadyStarted, setCallAlreadyStarted] = useState(false);
  const [buttonKeyForChatPanel, setButtonKeyForChatPanel] = useState(false);
  const [chatPanelHasOpen, setChatPanelHasOpen] = useState(true);
  const [userIsOwner, setUserIsOwner] = useState(true);
  const [ownerHaveSubscription, setOwnerHaveSubscription] = useState(false);
  const [checkForOwner, setCheckForOwner] = useState(false);
  const [socketHits, setSocketHits] = useState(0);
  let audio = new Audio(notifySound);
  const [isBrowserTypeSafari, setIsBrowserTypeSafari] = useState(false);
  const [totalJobsCountTechnician, setTotalJobsCountTechnician] = useState(0);
  const [checkCallTypes, setCheckCallTypes] = useState();
  const [country, setCountry] = useState("");

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -11,
      top: 18,
      backgroundColor: "red",
      padding: "0 4px",
    },
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  useEffect(() => {
    if (user) {
      mixpanel.track("Customer - On Start Call Page ", { Email: user.email });
      const timezone = getTimezone(user?.timezone);
      setCountry(timezone?.countries[0] ? timezone?.countries[0] : "US");
    }
  }, [user]);

  const BackToDashBoard = () => {
    Modal.confirm({
      title: "Are you sure you want to decline this technician?",
      okText: "Yes",
      cancelText: "No",
      className: "app-confirm-modal",
      onOk() {
        BackToDashBoardSubmit();
      },
    });
  };

  function formatPhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the phone number, except for "+"
    const cleanedPhoneNumber = phoneNumber.replace(/[^\d+]/g, "");

    // Check if the cleaned phone number is empty or not
    if (cleanedPhoneNumber.length === 0) {
      return phoneNumber;
    }

    // Format the phone number with dashes, preserving the leading "+"
    const formattedPhoneNumber = cleanedPhoneNumber.replace(
      /(\d{1,3})(\d{3})(\d{3})(\d{4})/,
      "$1-$2-$3-$4"
    );

    return formattedPhoneNumber;
  }

  const iCantMeetRightNow = () => {
    Modal.confirm({
      title: "Are you sure you want to Cancel this Job?",
      okText: "Yes",
      cancelText: "No",
      className: "app-confirm-modal",
      onOk() {
        iCantMeetRightNowCancelJob();
      },
    });
  };

  const handleSocketEvent = () => {
    // Increase the socket hits count
    setSocketHits((prevHits) => prevHits + 1);
    // Perform other actions
    audio.play();
  };

  /**
   * This function takes the user to dashboard.
   * @params = no params
   * @response : It redirects to dashboard page.
   * @author : Manibha
   */
  const switchToDashBoard = (e) => {
    e.currentTarget.disabled = true;
    window.location.href = "/";
  };

  useEffect(() => {
    if (job && job.status === "Inprogress") {
      setCallAlreadyStarted(true);
    }
  }, [job]);

  useEffect(() => {
    let tokenFromCookie = getCookie("user_access");
    if (tokenFromCookie) {
      console.log(
        "Looking for tokenFromCookie from WaitForMeetingStart - found",
        {token : "..."+tokenFromCookie.substring(tokenFromCookie.length - 10)}
      );
    } else {
      console.log(
        "Looking for tokenFromCookie from WaitForMeetingStart - not found"
      );
    }
    let userAgent = navigator.userAgent;
    let browserInfo =
      userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    let browserName = browserInfo[1];
    console.log("browserName>>>>>>>", browserName);
    if (browserName === "Safari") {
      setIsBrowserTypeSafari(true);
    } else {
      setIsBrowserTypeSafari(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (user.ownerId && user.ownerId !== null) {
        setUserIsOwner(false);
        const ownerInfoObject = await UserApi.getUserById(user.ownerId);
        if (ownerInfoObject) {
          if (
            ownerInfoObject.customer &&
            ownerInfoObject.customer.subscription
          ) {
            setOwnerHaveSubscription(true);
            setCheckForOwner(true);
          } else {
            setCheckForOwner(true);
          }
        } else {
          setCheckForOwner(true);
        }
      } else {
        setCheckForOwner(true);
      }
    })();
    setMethod("ComputerAudio");
    setShowLine(false);
    setShowEditor(false);
    socket.emit("join", jobId);
  }, []);

  useEffect(() => {
    socket.on("call:started-customer", () => {
      setCallAlreadyStarted(true);
    });

    socket.on("open-chat-panel-talkjs-for-customer", (data) => {
      if (data?.jobId === jobId) {
        handleSocketEvent();
      }
    });
  }, [socket]);

  socket.on("phone-from-tech", () => {
    window.localStorage.setItem("callFromSystem", true);
  });

  useEffect(() => {
    let callFromSystem = localStorage.getItem("callFromSystem");
    if (callFromSystem && user.userType === "customer") {
      openNotificationWithIcon(
        "info",
        "Info",
        `System calling you on your phone number ${user.customer.phoneNumber}.`
      );
      window.localStorage.removeItem("callFromSystem");
    }
  }, []);
  
  const BackToDashBoardSubmit = async () => {
        setDeclineCallDisable(true)
        const res = await JobApi.retrieveJob(jobId);
        if (res.status == "Inprogress" || res.status == "long-job") {
            history.push("/");
        } else if (res.status == "Completed") {
            history.push("/");
        } else {
            var dec_arr = job.tech_declined_ids
            dec_arr.push(job.technician.id)
            let tempdecOb = [...job.declinedByCustomer]
            tempdecOb.push(job.technician.id)            
            let dataToUpdate = { "status": "Waiting", "technician": "","keepSearching":true, "declinedByCustomer": tempdecOb, "post_again_reference_technician": "" }
            const updatedJobData = await JobApi.updateJob(jobId,dataToUpdate )
            updateReadStatus({ "job": jobId, "user": job.technician.user.id, status: false })
            await deleteParticipant(jobId, job?.technician?.user?.id)
            try {
                const webdata = await WebSocket.create({
                    user: user.id,
                    job: job.id,
                    socketType: 'technician-declined',
                    userType: user.userType,
                    hitFromCustomerSide: true,
                });

        job["web_socket_id"] = webdata["websocket_details"]["id"];
        await WebSocket.technicianDeclined({
          jobId: jobId,
          tech: job.technician,
          job: job,
        });
        console.log(
          "Sending job notification to rest of the eligible technicians",
          updatedJobData
        );
        socket.emit("search-for-tech", {
          jobData: updatedJobData,
          keepSearching: true,
        });
      } catch (err) {
        console.log("onSubmit error in InviteTech page>>>", err);
        await WebSocket.technicianDeclined({
          jobId: jobId,
          tech: job.technician,
          job: job,
        });
      }

      // mixpanel code//
      mixpanel.identify(user.email);
      mixpanel.track("Customer - Decline technician", { JobId: job.id });
      // mixpanel code//
      await JobCycleApi.create(JobTags.CUSTOMER_DECLINED_TECH, job.id, user.id);
      history.push("/");
    }
  };

  const iCantMeetRightNowCancelJob = async () => {
    // setDeclineCallDisable(true)
    const res = await JobApi.retrieveJob(jobId);
    if (res.status == "Inprogress" || res.status == "long-job") {
      history.push("/");
    } else if (res.status == "Completed") {
      history.push("/");
    } else {
      const updatedJobData = await JobApi.updateJob(jobId, {
        status: "Declined",
      });
      // console.log("Declined job :::", updatedJobData);

      // mixpanel code//
      mixpanel.identify(user.email);
      mixpanel.track("Customer - Cancel job", { JobId: job.id });
      await JobCycleApi.create(JobTags.CANCEL_JOB, job.id, user.id);
      // mixpanel code//

      history.push("/");
    }
  };

  const HandleInputDisplay = () => {
    setShowEditor(true);
  };

  const handelShowChat = () => {
    setButtonKeyForChatPanel(true);
    setChatPanelHasOpen(false);
    setSocketHits(0);
  };

  // This function will remove particiapnt from twilio conversation when customer decline that participant
  const deleteParticipant = async (jobId, techId) => {
    try {
      const getTwilioResponse = await TwilioApi.getTwilioChatDetails({
        chat_id: jobId,
      });
      if (getTwilioResponse) {
        const chatId =
          getTwilioResponse.conversation[0]?.twilio_chat_service?.sid;
        if (chatId) {
          const findParticipants =
            await TwilioApi.getTwilioUnreadMessageResponse(chatId);
          const participantList = findParticipants?.data?.participants;
          if (participantList && techId) {
            const filteredParticipants = participantList.filter(
              (participant) => {
                const attributes = JSON.parse(participant.attributes);
                return attributes.userId == techId;
              }
            );
            if (
              filteredParticipants &&
              filteredParticipants[0] &&
              filteredParticipants[0]?.sid
            ) {
              const participantID = filteredParticipants[0]?.sid;
              await TwilioApi.deleteParticipantFromChat(chatId, participantID);
              return;
            }
          }
        }
      }
      return;
    } catch (error) {
      console.log(
        "error while deleting participant from list of conversation",
        error
      );
      return;
    }
  };

  /**
   * Handling on submit of start call button by customer
   * @params = no params
   * @response : it redirects the customer to meeting page according to the phone/computer audio selection.
   * @author : Manibha
   **/
  const onSubmit = async (callType) => {
    const res = await JobApi.retrieveJob(jobId);
    socket.emit("meeting-started-by-customer", { jobData: job });
    if (res.status == "Inprogress" || res.status == "long-job") {
      openNotificationWithIcon("error", "Error", "Job is already in progress.");
      setTimeout(() => {
        window.location.href = `/dashboard`;
      }, 2000);
    } else if (res.status == "Accepted") {
      if (!res.GA_start_call_event_called) {
        //GA3 tag commented by Vinit on 24/04/2023.
        GAevent(
          "Call Started",
          "customer-start-call",
          res.id,
          res?.customer?.id
        );
        if (process.env.REACT_APP_URL) {
          const appUrl = process.env?.REACT_APP_URL?.split("/")[2] || false;
          PushUserDataToGtm("call_started", user, appUrl);
        }
        await updateJob(res.id, { GA_start_call_event_called: true });
      }
      switchToMeetingPage(callType);
    } else if (res.status == "Completed") {
      openNotificationWithIcon("error", "Error", "Job is already completed.");
      setTimeout(() => {
        window.location.href = `/dashboard`;
      }, 2000);
    }
  };

  /**
   * If the job is accepted then it makes changes in database and send both C and T to meeting page on submit of start call button.
   * @params = no params
   * @response : It redirects the customer to meeting page.
   * @author : Manibha
   **/
  const switchToMeetingPage = async (callType) => {
    // mixpanel code//
    mixpanel.identify(user.email);
    mixpanel.track("Customer - Start call with technician", { JobId: job.id });
    // mixpanel code//
    console.log("jobMethodType :::::: ", callType);
    await updateJob(jobId, { callStartType: callType ,keepSearching:false });;
    if (callType === "Phone") {
      if (EditPhoneNum !== 0) {
        if (
          isPossiblePhoneNumber(EditPhoneNum) === false ||
          isValidPhoneNumber(EditPhoneNum) === false
        ) {
          setStartCallDisablePhone(false);
          setDeclineCallDisable(false);
          return openNotificationWithIcon(
            "error",
            "Error",
            "Phone Number Not Valid"
          );
        }

        if (!EditPhoneNum) {
          setStartCallDisablePhone(false);
          setDeclineCallDisable(false);
          setError({ ...error, EditPhoneNum: "Please add your phone number." });
          return;
        }

        if (!pattern.test(EditPhoneNum)) {
          setStartCallDisablePhone(false);
          setDeclineCallDisable(false);
          setError({
            ...error,
            EditPhoneNum: "Please provide valid phone number.",
          });
          return;
        }
      }

      if (!pattern.test(EditPhoneNum)) {
        setError({
          ...error,
          EditPhoneNum: "Please provide valid phone number.",
        });
        // console.log("need error ", EditPhoneNum);
        setStartCallDisablePhone(false);
        setDeclineCallDisable(false);
        return;
      }

      await CustomerApi.updateCustomer(customerId, {
        phoneNumber: EditPhoneNum,
      })
        .then(() => {
          setPhoneNum(EditPhoneNum);
          fetchJob(jobId);
        })
        .catch(() => {
          console.log("Error in handle Phone save");
        });
    }

    fetchJob(jobId);
    try {
      await deleteParticipantForKeepSearching(job,job.technician.id)
    } catch (err) {
      console.log("Job data in switchToMeetingPage() is ",job);
      console.log("Handle catch block while  deleteParticipantForKeepSearching >>>", err);
    }

    try {
      const webdata = await WebSocket.create({
        user: user.id,
        job: job.id,
        socketType: "accept-job",
        userType: user.userType,
        hitFromCustomerSide: true,
      });

      job["web_socket_id"] = webdata["websocket_details"]["id"];

      await WebSocket.customer_start_call(job);
    } catch (err) {
      console.log("onSubmit error in InviteTech page>>>", err);

      await WebSocket.customer_start_call(job);
    }
    socket.emit("job-taken-notification", job.id);
    get_or_set_cookie(user);
    let lifeCycleTag = "";
    if (job && job.is_transferred && job.is_transferred == true) {
      lifeCycleTag = JobTags.CUSTOMER_START_CALL_AFTER_TRANSFER;
    } else {
      lifeCycleTag = JobTags.CUSTOMER_START_CALL;
    }

    await JobCycleApi.create(lifeCycleTag, jobId);
    window.location.href =
      process.env.REACT_APP_MEETING_PAGE + `/meeting/customer/${jobId}`;
  };

  /**
   * Starts a call
   * @params =
   * @response : it redirects the customer to meeting page.
   * @author : Kartar Singh
   */

  const onHandelComputerAudio = async () => {
    try {
      // Update state values
      setJobMethodType("ComputerAudio");
      setCheckCallTypes("ComputerAudio");
      setStartCallDisable(true);
      setDeclineCallDisable(true);
      setMethod("ComputerAudio");
      setShowLine(false);
      setShowEditor(false);

      // Emit a socket event
      socket.emit("set-method", { method: "ComputerAudio" });

      // Mixpanel code (assuming mixpanel is properly initialized)
      mixpanel.identify(user.email);
      mixpanel.track("Customer - Choose computer audio", { JobId: job.id });
    } catch (error) {
      // Handle any errors that may occur during the execution
      console.error("Error in onHandleComputerAudio:", error);
    }
  };

  /**
   * Starts a call
   * @params =
   * @response : it redirects the customer to meeting page.
   * @author : Kartar Singh
   */
  const onHandelCallMe = () => {
    try {
      // Update state values
      setCheckCallTypes("Phone");
      setJobMethodType("Phone");
      setStartCallDisablePhone(true);
      setDeclineCallDisable(true);
      setStartCallDisablePhone(true);
      setMethod("Phone");
      setShowLine(true);

      // Emit a socket event
      socket.emit("set-method", { method: "Phone" });

      // Mixpanel code (assuming mixpanel is properly initialized)
      mixpanel.identify(user.email);
      mixpanel.track("Customer - Choose choose with phone", { JobId: job.id });

      // Wait for the asynchronous onSubmit() to complete
    } catch (error) {
      // Handle any errors that may occur during the execution
      console.error("Error in onHandelCallMe:", error);
    }
  };

  /**
   * Starts a call on technician side
   * @params =
   * @response : it redirects the customer to meeting page, if meeting is already started by the customer.
   * @author : Sahil
   */

  const sendCustomerToMeeting = () => {
    if (checkCallTypes === "ComputerAudio") {
      setStartCallDisable(true);
    } else {
      setStartCallDisablePhone(true);
    }
    get_or_set_cookie(user);
    // mixpanel code//
    mixpanel.identify(user.email);
    mixpanel.track("Customer - Join Meeting", { JobId: job.id });
    // mixpanel code//
    window.location.href =
      process.env.REACT_APP_MEETING_PAGE + `/meeting/customer/${jobId}`;
  };

  const SwitchHandler = (checked) => {
    // console.log("checked>>>>>>>>>>", checked);
    if (checked) {
      setMethod("ComputerAudio");
      setShowLine(false);
      setShowEditor(false);
      $(".switchClassComp").addClass("computer");
      $(".switchClassPhone").removeClass("phone");
      setJobMethodType("ComputerAudio");
      socket.emit("set-method", { method: "ComputerAudio" });
      //mixpanel code //
      mixpanel.identify(user.email);
      mixpanel.track("Customer - Choose computer audio", { JobId: job.id });
      //mixpanel code //
    } else {
      setMethod("Phone");
      setShowLine(true);
      $(".switchClassPhone").addClass("phone");
      $(".switchClassComp").removeClass("computer");
      setJobMethodType("Phone");
      socket.emit("set-method", { method: "Phone" });
      //mixpanel code //
      mixpanel.identify(user.email);
      mixpanel.track("Customer - Choose choose with phone ", { JobId: job.id });
      //mixpanel code //
    }
  };

  useEffect(() => {
    if (job && job.id === jobId) {
      if (job.technician && job.technician.rating) {
        if (job?.technician && job?.technician?.rating !== undefined) {
          setTechRating(parseFloat(job?.technician?.rating).toFixed(2));
        }
      } else {
        setTechRating(parseFloat(5.0).toFixed(1));
      }

      setCustomerId(job.customer.id);
      setEditPhone(job.customer.phoneNumber);
      setPhoneNum(job.customer.phoneNumber); // "Some User token"
      let number = formatPhoneNumber(job.customer.phoneNumber);
      setPhoneNumUi(number);
      // console.log("customer extension ", job.customer.extension);
      setIsLoading(false);
    }

    if (job.status === "Completed") {
      openNotificationWithIcon(
        "info",
        "Info",
        "This job has already been completed. Please go to dashboard."
      );
    }
  }, [job]);

  useEffect(() => {
    (async () => {
      if (job && job?.id === jobId) {
        if (job?.technician) {
          let totalJobsCount =
            await getTotalJobsForTechnicianWithoutAuthenticate({
              technician: job?.technician?.id,
            });
          totalJobsCount = totalJobsCount ? totalJobsCount : 0
          setTotalJobsCountTechnician(totalJobsCount);
        }
      }
    })();
  }, [job]);

  const onSubmitCall = async () => {
    onHandelCallMe();
    await onSubmit("Phone");
  };
  const onSubmitComputerAudio = async () => {
    onHandelComputerAudio();
    await onSubmit("ComputerAudio");
  };

  if (isLoading)
    return (
      <Col md="12" className="px-4 py-5">
        <Row>
          <Loader
            height="100%"
            className={"mt-5 " + (isLoading ? "loader-outer" : "d-none")}
          />
        </Row>
      </Col>
    );
  return (
    <>
      <Modal
        footer={null}
        closable={false}
        visible={isBrowserTypeSafari}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={false}
        width={616}
      >
        <div className="">
          <span style={{ fontSize: "18px" }}>
            To fully experience our share screen and remote access features,
            Geeker recommends switching to{" "}
            <span style={{ fontWeight: "bold" }}>Google Chrome</span> browser.
          </span>
        </div>

        <div className="d-flex justify-content-end">
          <BasicButton
            onClick={() => setIsBrowserTypeSafari(false)}
            btnTitle={"Close"}
            height={"40px"}
            width={"100px"}
            background={"#1bd4d5"}
            color={"#fff"}
          />
        </div>
      </Modal>

      <div
        className={`help-on-its-way-back-div  ${
          buttonKeyForChatPanel ? "" : "h-100vh"
        }`}
      >
        <HeadingWithLogo />
        <div className="help-on-its-way-content-div d-flex flex-column gap-5">
          <div
            className="help-on-its-way-content"
            style={
              callAlreadyStarted
                ? { minHeight: "607px" }
                : { minHeight: "701px" }
            }
          >
            <div clastName="help-on-its-way-upper-div">
              <div className="geek-has-found-hader mb-14">
                <div className="geek-has-found-hader-inner">
                  <span>A geek has been found!</span>
                </div>
              </div>
              <div className="tech-info-div">
                <div className="tech-info-div-inner">
                  <div className="tech-profile-pick">
                    <Stack direction="row" spacing={2}>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        badgeContent={
                          <SmallAvatar
                            alt="Remy Sharp"
                            src={job?.software?.blob_image}
                          />
                        }
                      >
                        <Avatar
                          sx={{ width: 56, height: 55 }}
                          alt="Travis Howard"
                          src={
                            job?.technician?.profile?.image
                              ? job?.technician?.profile?.image
                              : profileImg
                          }
                        />
                      </Badge>
                    </Stack>
                  </div>
                  <div className="side-div-tech">
                    <div className="upper-tech-div">
                      <div className="upper-tech-div-text">
                        <span>
                          {job?.technician?.user?.firstName}{" "}
                          {job?.technician?.user?.lastName}
                        </span>
                      </div>
                      <div className="flag-div">
                        <div className="flag-div-inner">
                          <ReactCountryFlag
                            className="tech-flag"
                            countryCode={country}
                            style={{
                              fontSize: "1.5em",
                              lineHeight: "1em",
                            }}
                            aria-label="United States"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center flex-column align-items-center rating-container">
                      <div className="mr-3">
                        <Rating
                          name="simple-controlled"
                          value={techRating}
                          readOnly
                          precision={0.5}
                          className="start-rating-tech"
                        />
                      </div>
                      <div className="total-tech-jobs">
                        {totalJobsCountTechnician && parseInt(totalJobsCountTechnician) >= 10 && (
                          <span>{`${totalJobsCountTechnician} Jobs Completed`}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="find-another-tech">
                  {!callAlreadyStarted && job.status !== "Completed" && (
                    <Stack spacing={2} direction="row">
                      <Button
                        className=""
                        variant="text"
                        onClick={BackToDashBoard}
                        disabled={
                          isLoading || startCallDisable || declineCallDisable
                        }
                      >
                        <span className="find-another-tech-2">
                          Find another tech
                        </span>
                      </Button>
                    </Stack>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <div className="last-container-accp-job">
                  <div className="last-container-accp-job-inner">
                    <div className="cus-accept-btns">
                      {!callAlreadyStarted && job.status != "Completed" && (
                        <div className="last-container-accp-job-hader">
                          <span className="last-container-accp-job-text">
                            Please select the audio for your screen share{" "}
                          </span>
                        </div>
                      )}
                      {job.status === "Completed" && (
                        <div className="last-container-accp-job-hader">
                          <span className="last-container-accp-job-text">
                            This job has already been completed. Please go to
                            dashboard.{" "}
                          </span>
                        </div>
                      )}
                      <div className="d-flex justify-content-center align-items-start flex-column gap-10">
                        <div className="btn-accept-cus">
                          {!callAlreadyStarted &&
                            job.status !== "Completed" && (
                              <div>
                                <Stack
                                  className="stack-btn-cus"
                                  spacing={2}
                                  direction="row"
                                >
                                  <Button
                                    className={`cus-call-me-btn ${
                                      startCallDisablePhone
                                        ? "minwidth-258"
                                        : ""
                                    }`}
                                    onClick={onSubmitCall}
                                    disabled={
                                      startCallDisablePhone ||
                                      declineCallDisable
                                    }
                                    variant="contained"
                                  >
                                    <span className="cus-call-me-icon">
                                      {startCallDisablePhone ? (
                                        <Spin style={{ color: "black" }} />
                                      ) : (
                                        <div className="d-flex justify-content-center align-items-center">
                                          <img
                                            src={callIcon}
                                            style={{
                                              width: "24px",
                                              height: "24px",
                                              display: "inline-block",
                                              marginRight: "12px",
                                            }}
                                            alt="Computer Icon"
                                          />
                                          Call{" "}
                                          {formatPhoneNumber(
                                            EditPhoneNum || phoneNumUi
                                          )}
                                        </div>
                                      )}
                                    </span>
                                  </Button>
                                </Stack>
                                <div className="edit-number-cus ">
                                  <div className="d-flex flex-column text-center align-items-center justify-content-center gap-10">
                                    <div className="d-flex  align-items-center justify-content-center">
                                      <Stack spacing={2} direction="row">
                                        <Button
                                          className=""
                                          variant="text"
                                          onClick={HandleInputDisplay}
                                          disabled={startCallDisable}
                                        >
                                          <span className="cus-cant-meet-container-text brdr-btm">
                                            Use a different number
                                          </span>
                                        </Button>
                                      </Stack>
                                    </div>

                                    {showeditor && (
                                      <div
                                        className="mb-20 edit-phone-no"
                                        style={{ width: "100%" }}
                                      >
                                        <PhoneInput
                                          countryCodeEditable={false}
                                          name={"phoneNumber"}
                                          country="us"
                                          onlyCountries={[
                                            "in",
                                            "gr",
                                            "us",
                                            "ca",
                                            "gb",
                                          ]}
                                          className={`p-0 choose-your-password-phone-input`}
                                          dropdownClass="phoneInputDropdownClass"
                                          value={phoneNum}
                                          onChange={(e) => {
                                            setEditPhone("+" + e);
                                          }}
                                          inputClass="business-plan-phone-input-edit country-code-textarea "
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          {!callAlreadyStarted &&
                            job.status !== "Completed" && (
                              <Stack spacing={2} direction="row">
                                <Button
                                  className="cus-comp-btn"
                                  onClick={onSubmitComputerAudio}
                                  disabled={
                                    startCallDisable || declineCallDisable
                                  }
                                  variant="contained"
                                >
                                  <span className="cus-comp-icon">
                                    {startCallDisable ? (
                                      <Spin className="spinner" />
                                    ) : (
                                      <div className="d-flex justify-content-center align-items-center automation-computer-audio">
                                        <img
                                          src={computerIcon}
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                            display: "inline-block",
                                            marginRight: "12px",
                                          }}
                                          alt="Computer Icon"
                                        />
                                        Computer Audio
                                      </div>
                                    )}
                                  </span>
                                </Button>
                              </Stack>
                            )}

                          {callAlreadyStarted && job.status !== "Completed" && (
                            <Stack spacing={2} direction="row">
                              <Button
                                className="cus-comp-btn"
                                onClick={sendCustomerToMeeting}
                                disabled={startCallDisable}
                                variant="contained"
                              >
                                <span className="cus-comp-icon">
                                  {" "}
                                  <span></span>{" "}
                                  {startCallDisable ? <Spin /> : "Join"}
                                </span>
                              </Button>
                            </Stack>
                          )}

                          {job.status === "Completed" && (
                            <Stack spacing={2} direction="row">
                              <Button
                                className="cus-comp-btn"
                                onClick={switchToDashBoard}
                                disabled={startCallDisable}
                                variant="contained"
                              >
                                <span className="cus-comp-icon">
                                  Back to Dashboard
                                </span>
                              </Button>
                            </Stack>
                          )}
                        </div>

                        {/* {(!callAlreadyStarted && job.status != "Completed") &&
                                                    <div className='edit-number-cus '>
                                                        <div className='d-flex flex-column text-center align-items-center justify-content-center gap-10'>
                                                            <div className='d-flex  align-items-center justify-content-center'>
                                                                
                                                                <Stack spacing={2} direction="row">
                                                                    <Button className='' variant="text" onClick={HandleInputDisplay} disabled={startCallDisable}>
                                                                        <span className='cus-cant-meet-container-text'>Use a different number</span>
                                                                    </Button>
                                                                </Stack>
                                                            </div>

                                                            {showeditor && <div className="mb-20 edit-phone-no" style={{ width: '100%' }}>
                                                                <PhoneInput countryCodeEditable={false} name={'phoneNumber'} country="us" onlyCountries={['in', 'gr', 'us', 'ca', 'gb']} className={`p-0 choose-your-password-phone-input`}
                                                                    dropdownClass="phoneInputDropdownClass" value={phoneNum}
                                                                    onChange={(e) => {
                                                                        setEditPhone('+' + e);
                                                                    }} inputClass="business-plan-phone-input-edit country-code-textarea " />
                                                            </div>}
                                                        </div>
                                                    </div>
                                                } */}
                      </div>
                    </div>
                    {buttonKeyForChatPanel && (
                      <div
                        className="d-flex justify-content-center new-mb-chat mt-4 chat-zindex"
                        style={{ width: "100%" }}
                      >
                        <ChatPanelTwilio
                          job={job}
                          width={"100%"}
                          height={"500px"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="happy-boy-img">
              <img src={happyBoy} alt="the good boy" />
            </div>
          </div>
          {!callAlreadyStarted && job.status != "Completed" && (
            <div className="cus-cant-meet-container d-flex justify-content-center align-items-center">
              <div className="cus-cant-meet-container-inner">
                <Stack spacing={2} direction="row">
                  <Button
                    className=""
                    variant="text"
                    onClick={iCantMeetRightNow}
                  >
                    <span className="cus-cant-meet-container-text">
                      I can’t meet right now
                    </span>
                  </Button>
                </Stack>
                <Stack spacing={2} direction="row">
                  {chatPanelHasOpen ? (
                    <StyledBadge badgeContent={socketHits} color="secondary">
                      <Button
                        className=""
                        variant="text"
                        onClick={handelShowChat}
                      >
                        <span className="cus-cant-meet-container-text">
                         Chat with technician
                        </span>
                      </Button>
                    </StyledBadge>
                  ) : (
                    <span></span>
                  )}
                </Stack>
              </div>
            </div>
          )}
        </div>
        <div className="footer-accept-job-cus">
          <span className="footer-accept-job-cus-text"></span>
        </div>
      </div>
    </>
  );
}

export default GeekHasBeenFound;
